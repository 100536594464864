import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Select,
  Stack,
} from "@chakra-ui/react";
import SwitchInput from "./switch-input";

const MultiSelectInput = (props) => {
  const { label, options, buttonProps, onClear, onApply } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  return (
    <Menu closeOnSelect={false} variant={"roundLeft"}>
      {({ onClose }) => (
        <>
          <MenuButton type="button" {...buttonProps}>
            {`${label}`}
          </MenuButton>
          <MenuList minWidth={"180px"}>
            <MenuOptionGroup
              title={undefined}
              className="toogleSwitch"
              defaultValue={selectedOptions}
              type="checkbox"
              onChange={(values) => {
                setSelectedOptions(values.filter((_) => _.length));
                props.onChange?.(values);
              }}
            >
              {options.map((option) => {
                return (
                  <MenuItemOption
                    paddingLeft={"0px"}
                    minWidth={"180px"}
                    type="button"
                    value={option}
                  >
                    {option}
                  </MenuItemOption>
                );
              })}
              <MenuDivider />
              <ButtonGroup marginTop={1} padding={2}>
                <Button variant="brandPrimary" onClick={() => onClear()}>
                  Clear
                </Button>
                <Button
                  variant="danger"
                  onClick={() => onApply(selectedOptions)}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
export default MultiSelectInput;
