import { LAYOUT_TOGGLE, REQUIRED_RESPONSE } from "../../utils/constants";

const initialState = {
  layout: {
    sidebar: "sidebar",
    wrapper: "wrapper",
    branch: "branch",
  },
  requiredData: {},
};

const mainReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case LAYOUT_TOGGLE: {
      return {
        ...state,
        layout: payload,
      };
    }
    case REQUIRED_RESPONSE: {
      return {
        ...state,
        requiredData: payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default mainReducer;
