import { ROUTE_LINKS } from "../core/utils/route-links";
import { MENU_NAMES } from "../core/utils/constants";

import Dashboard from "../pages/dashboard";
import Layout from "../theme/layout";

import SignIn from "../pages/auth/sign-in";
import Profile from "../pages/auth/profile";
import ForgotPassword from "../pages/auth/forgot-password";
import ResetPassword from "../pages/auth/reset-password";

import Account from "../pages/account/account";
import FundType from "../pages/account/fund-type/fund-type";
import Grade from "../pages/account/grade/grade";
import Bank from "../pages/account/bank/bank";

import Branch from "../pages/branch/branch";
import AddBranch from "../pages/branch/add-branch";
import PendingDocument from "../pages/branch/pending-document/pending-document";
import PendingWork from "../pages/branch/pending-work/pending-work";

import Enquiry from "../pages/enquiry/enquiry";
import AddEnquiry from "../pages/enquiry/add-enquiry";
import EditViewEnquiry from "../pages/enquiry/edit-view-enquiry";
import FeedbackReport from "../pages/enquiry/feedback-report/feedback-report";
import EnquiryStatus from "../pages/enquiry/enquiry-status/enquiry-status";
import Board from "../pages/enquiry/board/board";
import Qualification from "../pages/enquiry/qual-stream/qual-stream";
import ReferenceType from "../pages/enquiry/reference/reference-type";
import Reference from "../pages/enquiry/reference/reference";

import State from "../pages/general/state/state";
import District from "../pages/general/district/district";
import Module from "../pages/general/module/module";

import Immigration from "../pages/immigration/immigration";
import ViewImmigration from "../pages/immigration/view-immigration";
import AddImmigration from "../pages/immigration/create-immigration";
import CaseStatus from "../pages/immigration/file-info/case-status";
import FileType from "../pages/immigration/file-info/file-type";
import FileMode from "../pages/immigration/file-info/file-mode";
import FileDocument from "../pages/immigration/file-info/file-document";
import VisaCity from "../pages/immigration/visa-info/visa-city";
import VisaType from "../pages/immigration/visa-info/visa-type";
import VisaCollege from "../pages/immigration/visa-info/visa-college";
import VisaCountry from "../pages/immigration/visa-info/visa-country";
import VisaCourse from "../pages/immigration/visa-info/visa-course";

import Role from "../pages/role/role";

import User from "../pages/user/user";
import AddUser from "../pages/user/add-user";
import AddRole from "../pages/role/add-role";
import NoPageFound from "../pages/no-page-found";
import UnAuthorized from "../pages/un-authorized";
import ViewUser from "../pages/user/view-user";
import ViewPermission from "../pages/branch/view-permission/view-permission";
import ErrorBoundary from "../pages/error-boundary";
import ViewRole from "../pages/role/view-role";
import EditRole from "../pages/role/edit-role";
import DailyRoutine from "../pages/daily-routine/daily-routine";
import ViewPayment from "../pages/immigration/view-payment";
import AddPayment from "../pages/account/add-payment";

const CREATE_ROUTE = (path, element, exact = true) => {
  const CREATE_ROUTE = (path, element, exact = true) => ({
    path,
    element,
    exact,
  });
  return CREATE_ROUTE(path, element, (exact = true));
};

const AppRoutes = [
  CREATE_ROUTE("*", <NoPageFound />),
  CREATE_ROUTE(
    "/",
    <Layout isNav={false} isFooter={false} isAuthRequired={false} Component={SignIn}>
      <SignIn />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.FORGOT_PASSWORD],
    <Layout isNav={false} isFooter={false} isAuthRequired={false} Component={ForgotPassword}>
      <ForgotPassword />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.RESET_PASSWORD],
    <Layout isNav={false} isFooter={false} isAuthRequired={false} Component={ResetPassword}>
      <ResetPassword />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.DASHBOARD],
    <Layout Component={Dashboard}>
      <Dashboard />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.DAILY_ROUTINE],
    <Layout Component={DailyRoutine}>
      <DailyRoutine />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.PROFILE],
    <Layout Component={Profile}>
      <Profile />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ACCOUNT],
    <Layout Component={Account}>
      <Account />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.BANK],
    <Layout Component={Bank}>
      <Bank />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.FUND_TYPE],
    <Layout Component={FundType}>
      <FundType />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.GRADE],
    <Layout Component={Grade}>
      <Grade />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.BRANCH],
    <Layout Component={Branch} />
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ADD_BRANCH],
    <Layout Component={AddBranch}>
      <AddBranch />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VIEW_PERMISSIONS],
    <Layout Component={ViewPermission}>
      <ViewPermission />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.PENDING_WORK],
    <Layout Component={PendingWork}>
      <PendingWork />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.PENDING_DOCUMENT],
    <Layout Component={PendingDocument}>
      <PendingDocument />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ENQUIRY],
    <Layout Component={Enquiry}>
      <Enquiry />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ADD_ENQUIRY],
    <Layout Component={AddEnquiry}>
      <AddEnquiry />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.EDIT_VIEW_ENQUIRY],
    <Layout Component={EditViewEnquiry}>
      <EditViewEnquiry />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ENQUIRY_STATUS],
    <Layout Component={EnquiryStatus}>
      <EnquiryStatus />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.FEEDBACK_REPORT],
    <Layout Component={FeedbackReport}>
      <FeedbackReport />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.QUAL_STREAM],
    <Layout Component={Qualification}>
      <Qualification />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.BOARD],
    <Layout Component={Board}>
      <Board />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.REFERENCE],
    <Layout Component={Reference}>
      <Reference />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.DISTRICT],
    <Layout Component={District}>
      <District />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.STATE],
    <Layout Component={State}>
      <State />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.MODULE],
    <Layout Component={Module}>
      <Module />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.GENERAL_ROLE],
    <Layout Component={Role}>
      <Role />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ADD_ROLE],
    <Layout Component={AddRole}>
      <AddRole />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VIEW_ROLE],
    <Layout Component={ViewRole}>
      <ViewRole />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.EDIT_ROLE],
    <Layout Component={EditRole}>
      <EditRole />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.GENERAL_USER],
    <Layout Component={User}>
      <User />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ADD_USER],
    <Layout Component={AddUser}>
      <AddUser />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.EDIT_USER],
    <Layout Component={AddUser}>
      <AddUser />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.IMMIGRATION],
    <Layout Component={Immigration}>
      <Immigration />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ADD_IMMIGRATION],
    <Layout Component={AddImmigration}>
      <AddImmigration />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.EDIT_IMMIGRATION],
    <Layout Component={AddImmigration}>
      <AddImmigration />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.FILE_STATUS],
    <Layout Component={CaseStatus}>
      <CaseStatus />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.FILE_TYPE],
    <Layout Component={FileType}>
      <FileType />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.FILE_MODE],
    <Layout Component={FileMode}>
      <FileMode />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.FILE_DOCUMENT],
    <Layout Component={FileDocument}>
      <FileDocument />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VIEW_PAYMENT],
    <Layout Component={ViewPayment}>
      <ViewPayment />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ADD_PAYMENT],
    <Layout Component={AddPayment}>
      <AddPayment />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VISA_CITY],
    <Layout Component={VisaCity}>
      <VisaCity />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VISA_TYPE],
    <Layout Component={VisaType}>
      <VisaType />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VISA_COLLEGE],
    <Layout Component={VisaCollege}>
      <VisaCollege />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VISA_COUNTRY],
    <Layout Component={VisaCountry}>
      <VisaCountry />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VISA_COURSE],
    <Layout Component={VisaCourse}>
      <VisaCourse />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VIEW_IMMIGRATION],
    <Layout Component={ViewImmigration}>
      <ViewImmigration />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.NOTFOUND],
    <Layout isNav={false} isFooter={false} isAuthRequired={false} Component={NoPageFound}>
      <NoPageFound />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.UN_AUTHORIZED],
    <Layout isNav={false} isFooter={false} isAuthRequired={false} Component={UnAuthorized}>
      <UnAuthorized />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.ERROR_BOUNDARY],
    <Layout isNav={false} isFooter={false} isAuthRequired={false} Component={ErrorBoundary}>
      <ErrorBoundary />
    </Layout>
  ),
  CREATE_ROUTE(
    ROUTE_LINKS[MENU_NAMES.VIEW_USER],
    <Layout Component={ViewUser}>
      <ViewUser />
    </Layout>
  ),
];
export default AppRoutes;
