import React, { useEffect } from "react";
import { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Heading,
  SimpleGrid,
  CircularProgress,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import { MENU_NAMES, ROLES } from "../../core/utils/constants";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { colors } from "../../theme/styles/colors";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextArea from "../../theme/components/input/text-area";
import SelectInput from "../../theme/components/input/select-input";
import { getAll, getUsersByRole } from "../../core/utils/common-function";
import { useDispatch, useSelector } from "react-redux";
import { getBranchID } from "../../core/store/actions";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import api from "../../core/api";
import { ENQUIRY_PAYLOAD } from "./enquiry-payload";
import { SuccessAlert } from "../../core/utils/common-sweet-alert";
import SwitchInput from "../../theme/components/input/switch-input";

const AddEnquiry = () => {
  const navigate = useNavigate();
  const selector = useSelector((_) => _);
  const dispatch = useDispatch();
  const params = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [branchId, setBranchId] = useState(selector.branch.userBranchId);
  const [counsellorState, setCounsellorState] = useState([]);
  const [branch, setBranch] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [board, setBoard] = useState([]);
  const [visaCountry, setVisaCountry] = useState([]);
  const [visaType, setVisaType] = useState([]);
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [reference, setReference] = useState([]);
  const [telecallerState, setTelecallerState] = useState([]);
  const [state, setState] = useState({
    cityDescription: "",
  });
  const [initialState, setInitialState] = useState({
    emailId: "",
    applicantName: "",
    boardId: "",
    qualification: "",
    father: "",
    address: "",
    phoneNumber: "",
    dob: "",
    lastPercentage: 0,
    passoutYear: 0,
    ielts: 0,
    listeningScore: 0,
    readingScore: 0,
    writingScore: 0,
    speakingScore: 0,
    remark: "",
    enquiryStatusId: 0,
    lastQualificationId: 0,
    travelHistory: false,
    travelRemark: "",
    refusal: false,
    refusalRemark: "",
  });
  const {
    ALPHA_NUMERIC_VALIDATOR,
    BOOLEAN_VALIDATOR,
    DATE_VALIDATOR,
    DECIMAL_VALIDATOR,
    POSITIVE_INTEGER,
    PASSWORD_VALIDATOR,
    PHONE_NUMBER_VALIDATOR,
    GUID_VALIDATOR,
    STRING_VALIDATOR,
  } = SCHEMA_VALIDATOR;

  const validationSchema = yup.object({
    dob: DATE_VALIDATOR,
    followUpDate: DATE_VALIDATOR,
    lastPercentage: POSITIVE_INTEGER,
    passoutYear: POSITIVE_INTEGER,
    ielts: DECIMAL_VALIDATOR,
    listeningScore: DECIMAL_VALIDATOR,
    readingScore: DECIMAL_VALIDATOR,
    writingScore: DECIMAL_VALIDATOR,
    speakingScore: DECIMAL_VALIDATOR,
    address: ALPHA_NUMERIC_VALIDATOR,
    remark: ALPHA_NUMERIC_VALIDATOR,
    phoneNumber: PHONE_NUMBER_VALIDATOR,
    name: STRING_VALIDATOR,
    enquiryStatusId: GUID_VALIDATOR,
    lastQualificationId: GUID_VALIDATOR,
    boardId: GUID_VALIDATOR,
    visaTypeId: GUID_VALIDATOR,
    visaCountryId: GUID_VALIDATOR,
    counsellorId: GUID_VALIDATOR,
    referenceId: GUID_VALIDATOR,
    travelHistory: BOOLEAN_VALIDATOR,
    travelRemark: yup.string().when("travelHistory", {
      is: true,
      then: () => STRING_VALIDATOR,
      otherwise: () => yup.string(),
    }),
    refusal: BOOLEAN_VALIDATOR,
    refusalRemark: yup.string().when("refusal", {
      is: true,
      then: () => STRING_VALIDATOR,
      otherwise: () => yup.string(),
    }),
  });

  useEffect(() => {
    document.title = params.state.isEdit ? "Edit Enquiry | Digital Filing" : "Add Enquiry | Digital Filing";
    getAllPromises();
  }, []);

  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const getAllPromises = () => {
    setBranchId(selector.branch.userBranchId);
    const getBranchRequest = getAll("Branch", payload);
    const VisaCountry = getAll("VisaCountry", payload);
    const Reference = getAll("Reference", payload);
    const VisaType = getAll("VisaType", payload);
    const Board = getAll("Board", payload);
    const Qualification = getAll("QualStream", payload);
    const EnquiryStatus = getAll("EnquiryStatus", payload);
    const getCounsellor = getUsersByRole(
      ROLES.COUNSELLOR,
      selector.branch.userBranchId
    );
    const getTelecaller = getUsersByRole(
      ROLES.TELE_CALLER,
      selector.branch.userBranchId
    );

    Promise.all([
      getBranchRequest,
      VisaCountry,
      EnquiryStatus,
      VisaType,
      Reference,
      Board,
      getCounsellor,
      getTelecaller,
      Qualification,
    ])
      .then((res) => {
        const [
          branchResponse,
          visaCountry1,
          enquiryStatus1,
          visaType1,
          reference1,
          board1,
          counsellor,
          telecaller,
          qualification1,
        ] = res;
        const Branch = branchResponse.data.data.map((branch) => ({
          text: branch.name,
          value: branch.branchId,
        }));
        const VisaCountry = visaCountry1.data.data.map((visaCountry1) => ({
          text: visaCountry1.name,
          value: visaCountry1.visaCountryId,
        }));
        const EnquiryStatus = enquiryStatus1.data.data.map(
          (enquiryStatus1) => ({
            text: enquiryStatus1.name,
            value: enquiryStatus1.enquiryStatusId,
          })
        );
        const Reference = reference1.data.data.map((reference1) => ({
          text: reference1.name,
          value: reference1.referenceId,
        }));
        const VisaType = visaType1.data.data.map((visaType1) => ({
          text: visaType1.name,
          value: visaType1.visaTypeId,
        }));
        const Board = board1.data.data.map((board1) => ({
          text: board1.name,
          value: board1.boardId,
        }));
        const counsellorResp = counsellor.data.map((counsler) => ({
          text: counsler.name,
          value: counsler.userId,
        }));
        const telecallerrResp = telecaller.data.map((telecaler) => ({
          text: telecaler.name,
          value: telecaler.userId,
        }));
        const Qualification = qualification1.data.data.map(
          (qualification1) => ({
            text: qualification1.name,
            value: qualification1.qualStreamId,
          })
        );
        setBranch(Branch);
        setVisaCountry(VisaCountry);
        setEnquiryStatus(EnquiryStatus);
        setVisaType(VisaType);
        setReference(Reference);
        setBoard(Board);
        setCounsellorState(counsellorResp);
        setTelecallerState(telecallerrResp);
        setQualification(Qualification);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <Container maxW="container.xll" padding={"20px "}>
      <Box>
        <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.ADD_ENQUIRY)} />
      </Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialState}
        enableReinitialize={true}
        onSubmit={(values) => {
          values.branchId = selector.branch.userBranchId;
          let finalPayload = ENQUIRY_PAYLOAD.CREATE(values);
          if (params.state.isEdit) {
            api
              .put("Enquiry/replace", values)
              .then((res) => {
                SuccessAlert("Enquiry updated successfully");
              })
              .catch((err) => {
                console.log(err, "error");
              });
            return;
          }
          api
            .post(`Enquiry/create`, { ...finalPayload })
            .then((res) => {
              navigate(ROUTE_LINKS[MENU_NAMES.ENQUIRY]);
              SuccessAlert("Enquiry Created successfully");
            })
            .catch((err) => {
              console.log(err, "err");
            });
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            noValidate={true}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box>
                <Heading variant={"h1"} marginBottom={"20px"}>
                  Create New Enquiry
                </Heading>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                marginBottom={"20px"}
              >
                <Button
                  variant={"warning"}
                  onClick={() => {
                    navigate(ROUTE_LINKS[MENU_NAMES.ENQUIRY]);
                  }}
                >
                  Discard
                </Button>
                <Button variant={"primary"} type="submit">
                  {isLoading ? (
                    <CircularProgress
                      isIndeterminate
                      size="24px"
                      color={colors.white}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Box>
            <Box
              display={"flex"}
              gap={"20px"}
              borderRadius={"10px"}
              flexDirection={"column"}
            >
              <Box
                padding={"20px"}
                borderRadius={"10px"}
                border={"1px solid #e6e6e6"}
              >
                <SimpleGrid
                  columns={{ base: "1", sm: "1", md: "3", lg: "3" }}
                  spacingX="30px"
                  spacingY="0px"
                >
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="name"
                    value={values.name}
                    label={{
                      text: "Applicant Name",
                      colors: errors.name ? "red !important" : "",
                      variant: errors.name ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.name ? "errored" : "flushed"}
                    isReadOnly={isLoading}
                  />
                  <TextInput
                    type="date"
                    name="dob"
                    isRequired={true}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.dob ? "errored" : "flushed"}
                    label={{
                      text: "DOB",
                      colors: errors.dob ? "red !important" : "",
                      variant: errors.dob ? "errored" : "",
                    }}
                    isReadOnly={isLoading}
                  />
                  <SelectInput
                    formControl={{ id: "enquiryStatusId", variant: "floating" }}
                    isRequired={true}
                    name="enquiryStatusId"
                    isReadOnly={isLoading}
                    value={values.enquiryStatusId}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    label={{
                      text: "Enquiry Status",
                      colors: errors.enquiryStatusId ? "red !important" : "",
                      variant: errors.enquiryStatusId ? "errored" : "",
                    }}
                    options={enquiryStatus}
                    variant={errors.enquiryStatusId ? "errored" : "flushed"}
                  />
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="phoneNumber"
                    value={values.phoneNumber}
                    label={{
                      text: "Phone Number",
                      colors: errors.phoneNumber ? "red !important" : "",
                      variant: errors.phoneNumber ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.phoneNumber ? "errored" : "flushed"}
                    isReadOnly={isLoading}
                  />
                  <TextInput
                    type="date"
                    name="followUpDate"
                    isRequired={true}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.followUpDate ? "errored" : "flushed"}
                    label={{
                      text: "Follow up",
                      colors: errors.followUpDate ? "red !important" : "",
                      variant: errors.followUpDate ? "errored" : "",
                    }}
                    isReadOnly={isLoading}
                  />
                  <TextInput
                    type="text"
                    name="emailId"
                    isRequired={false}
                    value={values.emailId}
                    label={{
                      text: "Email",
                      colors: errors.emailId ? "red !important" : "",
                      variant: errors.emailId ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.emailId ? "errored" : "flushed"}
                    isReadOnly={isLoading}
                  />
                  <TextInput
                    type="text"
                    isRequired={false}
                    name="father"
                    value={values.father}
                    label={{
                      text: "Father/Guardian",
                      colors: errors.father ? "red !important" : "",
                      variant: errors.father ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.father ? "errored" : "flushed"}
                    isReadOnly={isLoading}
                  />
                  <TextInput
                    type="text"
                    name="address"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.address}
                    variant={errors.address ? "errored" : "flushed"}
                    label={{
                      text: "Address",
                      colors: errors.address ? "red !important" : "",
                      variant: errors.address ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />
                  <SwitchInput
                    placeholder={""}
                    isRequired={false}
                    isReadOnly={isLoading}
                    value={values.travelHistory}
                    name="travelHistory"
                    variant={errors.travelHistory ? "errored" : "flushed"}
                    label={{
                      text: "Travel History(if any)",
                      colors: errors.travelHistory ? "red !important" : "",
                      variant: errors.travelHistory ? "errored" : "",
                      fontSize: "12px",
                    }}
                    formControl={{ id: "travelHistory" }}
                    onChange={handleChange}
                  />
                  {values.travelHistory && (
                    <TextInput
                      type="text"
                      name="travelRemark"
                      placeholder={""}
                      // isRequired={false}
                      isReadOnly={isLoading}
                      isRequired={values.travelHistory}
                      isDisabled={!values.travelHistory}
                      value={values.travelRemark}
                      variant={errors.travelRemark ? "errored" : "flushed"}
                      label={{
                        text: "Travel Remark",
                        colors: errors.travelRemark ? "red !important" : "",
                        variant: errors.travelRemark ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                  )}
                  <SwitchInput
                    placeholder={""}
                    isRequired={false}
                    isReadOnly={isLoading}
                    value={values.refusal}
                    name="refusal"
                    variant={errors.refusal ? "errored" : "flushed"}
                    label={{
                      text: "Refusal(if any)",
                      colors: errors.refusal ? "red !important" : "",
                      variant: errors.refusal ? "errored" : "",
                      fontSize: "12px",
                    }}
                    formControl={{ id: "refusal" }}
                    onChange={handleChange}
                  />
                  {values.refusal && (
                    <TextInput
                      type="text"
                      name="refusalRemark"
                      placeholder={""}
                      isReadOnly={isLoading}
                      isRequired={values.refusal}
                      isDisabled={!values.refusal}
                      value={values.refusalRemark}
                      variant={errors.refusalRemark ? "errored" : "flushed"}
                      label={{
                        text: "Refusal Remark",
                        colors: errors.refusalRemark ? "red !important" : "",
                        variant: errors.refusalRemark ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                  )}
                </SimpleGrid>
              </Box>
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem
                  borderRadius={"10px"}
                  border={"1px solid #e6e6e6"}
                >
                  <AccordionButton backgroundColor={"#ebebeb"}>
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Education Details
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel p={4}>
                    <Box>
                      <SimpleGrid
                        columns={{ base: "1", sm: "1", md: "3", lg: "3" }}
                        spacingX="30px"
                        spacingY="0px"
                      >
                        <Box w={"100%"} display={"flex"} gap={"10px"}>
                          <Box w={"50%"}>
                            <TextInput
                              type="number"
                              name="ielts"
                              placeholder={""}
                              isRequired={true}
                              isReadOnly={isLoading}
                              value={values.ielts}
                              variant={errors.ielts ? "errored" : "flushed"}
                              label={{
                                text: "Ielts Score",
                                colors: errors.ielts ? "red !important" : "",
                                variant: errors.ielts ? "errored" : "",
                              }}
                              onChange={handleChange}
                              width={"30px"}
                            />
                          </Box>
                          <Box w={"50%"}>
                            <Box
                              display={"flex"}
                              gap={"5px"}
                              justifyContent={"end"}
                            >
                              <TextInput
                                type="number"
                                name="listeningScore"
                                alignItems={"center"}
                                isRequired={true}
                                width={"30px"}
                                placeholder={""}
                                value={values.listeningScore}
                                label={{
                                  text: "L",
                                  colors: errors.listeningScore
                                    ? "red !important"
                                    : "",
                                  variant: errors.listeningScore
                                    ? "errored"
                                    : "",
                                }}
                                onChange={handleChange}
                                variant={
                                  errors.listening ? "errored" : "flushed"
                                }
                                isReadOnly={isLoading}
                              />
                              <TextInput
                                type="number"
                                name="writingScore"
                                alignItems={"center"}
                                isRequired={true}
                                width={"30px"}
                                placeholder={""}
                                value={values.writingScore}
                                label={{
                                  text: "W",
                                  colors: errors.writingScore
                                    ? "red !important"
                                    : "",
                                  variant: errors.writingScore ? "errored" : "",
                                }}
                                onChange={handleChange}
                                variant={
                                  errors.writingScore ? "errored" : "flushed"
                                }
                                isReadOnly={isLoading}
                              />
                              <TextInput
                                type="number"
                                name="readingScore"
                                alignItems={"center"}
                                isRequired={true}
                                width={"30px"}
                                placeholder={""}
                                value={values.readingScore}
                                label={{
                                  text: "R",
                                  colors: errors.readingScore
                                    ? "red !important"
                                    : "",
                                  variant: errors.readingScore ? "errored" : "",
                                }}
                                onChange={handleChange}
                                variant={
                                  errors.readingScore ? "errored" : "flushed"
                                }
                                isReadOnly={isLoading}
                              />
                              <TextInput
                                type="number"
                                name="speakingScore"
                                alignItems={"center"}
                                isRequired={true}
                                width={"30px"}
                                placeholder={""}
                                value={values.speakingScore}
                                label={{
                                  text: "S",
                                  colors: errors.speakingScore
                                    ? "red !important"
                                    : "",
                                  variant: errors.speakingScore
                                    ? "errored"
                                    : "",
                                }}
                                onChange={handleChange}
                                variant={
                                  errors.speakingScore ? "errored" : "flushed"
                                }
                                isReadOnly={isLoading}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <SelectInput
                          formControl={{
                            id: "qualStreamId",
                            variant: "floating",
                          }}
                          name="qualStreamId"
                          isRequired={true}
                          isReadOnly={isLoading}
                          value={values.qualStreamId}
                          label={{
                            text: "Last Qualification",
                            colors: errors.qualStreamId ? "red !important" : "",
                            variant: errors.qualStreamId ? "errored" : "",
                          }}
                          options={qualification}
                          variant={
                            errors.lastQualificationId ? "errored" : "flushed"
                          }
                          onChange={handleChange}
                        />
                        <TextInput
                          type="number"
                          name="passoutYear"
                          isRequired={true}
                          placeholder={""}
                          value={values.passoutYear}
                          label={{
                            text: "Passout year",
                            colors: errors.passoutYear ? "red !important" : "",
                            variant: errors.passoutYear ? "errored" : "",
                          }}
                          onChange={handleChange}
                          variant={errors.passoutYear ? "errored" : "flushed"}
                          isReadOnly={isLoading}
                        />
                        <SelectInput
                          formControl={{
                            id: "boardId",
                            variant: "floating",
                          }}
                          isRequired={true}
                          name="boardId"
                          isReadOnly={isLoading}
                          value={values.boardId}
                          label={{
                            text: "Board University",
                            colors: errors.boardId ? "red !important" : "",
                            variant: errors.boardId ? "errored" : "",
                          }}
                          onChange={handleChange}
                          options={board}
                          variant={errors.boardId ? "errored" : "flushed"}
                        />
                        <TextInput
                          type="number"
                          name="lastPercentage"
                          placeholder={""}
                          isRequired={true}
                          value={values.lastPercentage}
                          label={{
                            text: "Last lastPercentage(%)",
                            colors: errors.lastPercentage
                              ? "red !important"
                              : "",
                            variant: errors.lastPercentage ? "errored" : "",
                          }}
                          onChange={handleChange}
                          variant={
                            errors.lastPercentage ? "errored" : "flushed"
                          }
                          isReadOnly={isLoading}
                        />
                      </SimpleGrid>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>

              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem
                  borderRadius={"10px"}
                  border={"1px solid #e6e6e6"}
                >
                  <AccordionButton backgroundColor={"#ebebeb"}>
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Office Details
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel p={4}>
                    <Box>
                      <SimpleGrid
                        columns={{ base: "1", sm: "1", md: "3", lg: "3" }}
                        spacingX="30px"
                        spacingY="0px"
                      >
                        <SelectInput
                          formControl={{
                            id: "visaCountryId",
                            variant: "floating",
                          }}
                          name="visaCountryId"
                          isRequired={true}
                          isReadOnly={isLoading}
                          value={values.visaCountryId}
                          label={{
                            text: "Visa Country",
                            colors: errors.visaCountryId
                              ? "red !important"
                              : "",
                            variant: errors.visaCountryId ? "errored" : "",
                          }}
                          onChange={handleChange}
                          options={visaCountry}
                          variant={errors.visaCountryId ? "errored" : "flushed"}
                        />
                        <SelectInput
                          formControl={{
                            id: "counsellorId",
                            variant: "floating",
                          }}
                          name="counsellorId"
                          isRequired={true}
                          isReadOnly={isLoading}
                          value={values.counsellorId}
                          label={{
                            text: "Counsellor",
                            colors: errors.counsellorId ? "red !important" : "",
                            variant: errors.counsellorId ? "errored" : "",
                          }}
                          options={counsellorState}
                          variant={errors.counsellorId ? "errored" : "flushed"}
                          onChange={handleChange}
                        />
                        <SelectInput
                          formControl={{
                            id: "teleCallerId",
                            variant: "floating",
                          }}
                          name="teleCallerId"
                          isRequired={false}
                          isReadOnly={isLoading}
                          value={values.teleCallerId}
                          label={{
                            text: "Tele Caller",
                            colors: errors.teleCallerId ? "red !important" : "",
                            variant: errors.teleCallerId ? "errored" : "",
                          }}
                          options={telecallerState}
                          variant={errors.teleCallerId ? "errored" : "flushed"}
                          onChange={handleChange}
                        />
                        <SelectInput
                          formControl={{
                            id: "visaTypeId",
                            variant: "floating",
                          }}
                          name="visaTypeId"
                          isRequired={true}
                          isReadOnly={isLoading}
                          value={values.visaTypeId}
                          label={{
                            text: "Visa Type",
                            colors: errors.visaTypeId ? "red !important" : "",
                            variant: errors.visaTypeId ? "errored" : "",
                          }}
                          options={visaType}
                          variant={errors.visaTypeId ? "errored" : "flushed"}
                          onChange={handleChange}
                        />
                        <SelectInput
                          formControl={{
                            id: "referenceId",
                            variant: "floating",
                          }}
                          name="referenceId"
                          isRequired={true}
                          isReadOnly={isLoading}
                          value={values.referenceId}
                          label={{
                            text: "Reference",
                            colors: errors.referenceId ? "red !important" : "",
                            variant: errors.referenceId ? "errored" : "",
                          }}
                          options={reference}
                          variant={errors.referenceId ? "errored" : "flushed"}
                          onChange={handleChange}
                        />
                        <TextArea
                          name="remark"
                          isReadOnly={isLoading}
                          isRequired={true}
                          value={values.remark}
                          placeholder={""}
                          label={{
                            text: "Remarks",
                            colors: errors.remark ? "red !important" : "",
                            variant: errors.remark ? "errored" : "",
                          }}
                          onChange={handleChange}
                          variant={errors.remark ? "errored" : "flushed"}
                        />
                      </SimpleGrid>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddEnquiry;
