export const GRID_COLUMNS = {
  ENQUIRY: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForCaseStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "Enquiry Number", Alias: "Enquiry Number", Active: true, Visibility: true },
    { Name: "VisaCountry Name", Alias: "Country", Active: true, Visibility: true },
    {
      Name: "enquiryId",
      Alias: "enquiryId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "enquiryStatusId", Alias: "enquiryStatusId", Active: false, Visibility: false },
    { Name: "Last Remark", Alias: "Last Remark", Active: false, Visibility: true },
    { Name: "FollowUp Date", Alias: "FollowUp Date", Active: false, Visibility: true, ForDate: true },
    { Name: "Phone Number", Alias: "Phone Number", Active: false, Visibility: true },
    { Name: "Address", Alias: "Address", Active: false, Visibility: true },
    { Name: "DOB", Alias: "DOB", Active: false, Visibility: true, ForDate: true },
    { Name: "EmailId", Alias: "Email", Active: false, Visibility: true },
    { Name: "QualStream Name", Alias: "Qualification", Active: false, Visibility: true },
    { Name: "PercentageAge", Alias: "%Age", Active: false, Visibility: true },
    { Name: "Passout Year", Alias: "Passout Year", Active: false, Visibility: true },
    { Name: "Counsellor Name", Alias: "Counsellor", Active: false, Visibility: true },
    { Name: "Telecaller Name", Alias: "Telecaller", Active: false, Visibility: true },
    { Name: "Ielts Score", Alias: "Ielts Score ", Active: false, Visibility: true },
    { Name: "Reference Name", Alias: "Reference", Active: false, Visibility: true },
    { Name: "VisaType Name", Alias: "Visa Type", Active: false, Visibility: true },
    { Name: "Feedback", Alias: "Feedback", Active: false, Visibility: true },
  ],
  FEEDBACK_REPORT: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Enquiry Number", Alias: "Enquiry Number", Active: true, Visibility: true },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    {
      Name: "Counsellor Name",
      Alias: "Counsellor", 
      Active: true,
      Visibility: true,
    },
    {
      Name: "enquiryId",
      Alias: "enquiryId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "Branch Name", Alias: "Branch", Active: true, Visibility: true },
    { Name: "Remarks", Alias: "Remark", Active: true, Visibility: true },
  ],
  ENQUIRY_STATUS: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "enquiryStatusId",
      Alias: "enquiryStatusId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  BOARD: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "boardId", Alias: "boardId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  QUAL_STREAM: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "qualStreamId",
      Alias: "qualStreamId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  REFERENCE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "referenceId",
      Alias: "referenceId", Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  REFERENCE_TYPE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
  ],
  IMMIGRATION: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForCaseStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "File Number", Alias: "File Number", Active: true, Visibility: true },
    { Name: "VisaCountry Name", Alias: "Country", Active: true, Visibility: true },
    { Name: "caseStatusId", Alias: "caseStatusId", Active: false, Visibility: false },
    {
      Name: "immigrationFileId",
      Alias: "immigrationFileId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    {
      Name: "enquiryID",
      Alias: "enquiryID", 
      Active: false,
      Visibility: false,
    },
    { Name: "IeltsScore", Alias: "Ielts Score", Active: false, Visibility: true },
    { Name: "GradeName", Alias: "Grade", Active: false, Visibility: true },
    { Name: "Phone Number", Alias: "Phone Number", Active: false, Visibility: true },
    { Name: "DOB", Alias: "DOB", Active: false, Visibility: true, ForDate: true },
    { Name: "FileType Name", Alias: "File Type", Active: false, Visibility: true },
    { Name: "Passport Number", Alias: "Passport Number", Active: false, Visibility: true },
    { Name: "Board Name", Alias: "Board", Active: false, Visibility: true },
    { Name: "QualStream Name", Alias: "Qual/Stream", Active: false, Visibility: true },
    { Name: "Passout Year", Alias: "Passout Year", Active: false, Visibility: true },
    { Name: "Percentage", Alias: "%Age", Active: false, Visibility: true },
    { Name: "VisaCollege Name", Alias: "College", Active: false, Visibility: true },
    { Name: "Counsellor Name", Alias: "Counsellor", Active: false, Visibility: true },
    { Name: "VisaType Name", Alias: "Visa Type", Active: false, Visibility: true },
    { Name: "VisaCity Name", Alias: "City", Active: false, Visibility: true },
    { Name: "Duration", Alias: "Duration", Active: false, Visibility: true },
    { Name: "Reference Name", Alias: "Reference", Active: false, Visibility: true },
    { Name: "FileType Name", Alias: "Fund Type", Active: false, Visibility: true },
    { Name: "Intake Name", Alias: "Intake", Active: false, Visibility: true },
    { Name: "District Name", Alias: "District", Active: false, Visibility: true },
    { Name: "VisaCourse Name", Alias: "Course", Active: false, Visibility: true },
  ],
  VISA_TYPE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "visaTypeId",
      Alias: "visaTypeId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  VISA_COUNTRY: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "visaCountryId",
      Alias: "visaCountryId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  VISA_CITY: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "visaCityId",
      Alias: "visaCityId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  VISA_COLLEGE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "visaCollegeId",
      Alias: "visaCollegeId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    {
      Name: "VisaCountry Name",
      Alias: "Country", 
      Active: true,
      Visibility: true,
    },
    { Name: "VisaCity Name", Alias: "City", Active: true, Visibility: true },
  ],
  VISA_COURSE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "visaCourseId",
      Alias: "visaCourseId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  FILE_STATUS: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "caseStatusId",
      Alias: "caseStatusId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  FILE_TYPE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "fileTypeId", Alias: "fileTypeId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  FILE_MODE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "fileModeId", Alias: "fileModeId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  FILE_DOCUMENT: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "fileDocumentId",
      Alias: "fileDocumentId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
  ],
  BRANCH: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "State Name", Alias: "State", Active: true, Visibility: true },
    { Name: "District Name", Alias: "District", Active: true, Visibility: true },
    { Name: "StateId", Alias: "StateId", Active: false, Visibility: false },
    { Name: "BranchId", Alias: "BranchId", Active: false, Visibility: false, ForIdentity: true },
  ],
  ADD_BRANCH: [
    { Name: "Role Name", Alias: "Role", Active: true, Visibility: true },
    { Name: "User Name", Alias: "User", Active: true, Visibility: true },
  ],
  BRANCH_USER_PERMISSIONS: [
    { Name: "Role Name", Alias: "Role", Active: true, Visibility: true },
    { Name: "user", Alias: "User", Active: true, Visibility: true },
    { Name: "userName", Alias: "Username", Active: true, Visibility: true },
    { Name: "password", Alias: "Password", Active: true, Visibility: true },
  ],
  VIEW_PERMISSIONS: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Role", Alias: "Role", Active: true, Visibility: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "Username", Alias: "UserName", Active: true, Visibility: true },
    {
      Name: "roleId",
      Alias: "roleId", 
      Active: false,
      Visibility: false,
    },
    { Name: "userId", Alias: "userId", Active: false, Visibility: false, ForIdentity: true },
  ],
  PENDING_WORK: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "File Number", Alias: "File Number", Active: true, Visibility: true },
    {
      Name: "immigrationFileId",
      Alias: "immigrationFileId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    {
      Name: "enquiryID",
      Alias: "enquiryID", 
      Active: false,
      Visibility: false,
    },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    { Name: "Current State", Alias: "Current State", Active: true, Visibility: true },
    { Name: "Current Date", Alias: "Current Date", Active: true, Visibility: true, ForDate: true },
    { Name: "Previous State", Alias: "Previous State", Active: true, Visibility: true },
    { Name: "Previous Date", Alias: "Previous Date", Active: true, Visibility: true, ForDate: true },
  ],
  PENDING_DOCUMENT: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "File Number", Alias: "File Number", Active: true, Visibility: true },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    { Name: "Country Name", Alias: "Country", Active: true, Visibility: true },
    { Name: "Visa Type Name", Alias: "Visa Type", Active: true, Visibility: true },
    {
      Name: "Documents",
      Alias: "Complete Document", 
      Active: true,
      Visibility: true,
    },
    {
      Name: "immigrationFileId",
      Alias: "immigrationFileId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    {
      Name: "enquiryID",
      Alias: "enquiryID", 
      Active: false,
      Visibility: false,
    },
  ],
  FUND_TYPE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "fundTypeId", Alias: "fundTypeId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  GRADE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "gradeId", Alias: "gradeId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "grade Value", Alias: "Value", Active: true, Visibility: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  BANK: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "bankId", Alias: "bankId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  USER: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "Email Id", Alias: "Email", Active: true, Visibility: true },
    { Name: "Phone Number", Alias: "Phone Number", Active: true, Visibility: true },
    { Name: "DOB", Alias: "DOB", Active: true, Visibility: true, ForDate: true },
    { Name: "Address", Alias: "Address", Active: true, Visibility: true },
    { Name: "State Name", Alias: "State", Active: true, Visibility: true },
    { Name: "District Name", Alias: "District", Active: true, Visibility: true },
    { Name: "Is Admin", Alias: "IsAdmin", Active: false, Visibility: false },
    { Name: "Username", Alias: "Username", Active: false, Visibility: false },
    { Name: "ImagePath", Alias: "Profile", Active: false, Visibility: false },
    { Name: "UserId", Alias: "UserId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  ROLE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Role", Alias: "Name", Active: true, Visibility: true },
    { Name: "RoleId", Alias: "RoleId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  MODULE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "Route", Alias: "Route", Active: false, Visibility: false },
    { Name: "parentId", Alias: "parentId", Active: false, Visibility: false },
    { Name: "parent Name", Alias: "Parent", Active: true, Visibility: true },
    { Name: "ModuleId", Alias: "ModuleId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  STATE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    { Name: "StateId", Alias: "StateId", Active: false, Visibility: false, ForIdentity: true },
    { Name: "CreatedOn", Alias: "Created On", Active: false, Visibility: false },
  ],
  DISTRICT: [
    {
      Name: "Action",
      Alias: "Action",
      Active: false,
      Visibility: false,
      ForAction: true,
    },
    {
      Name: "Status",
      Alias: "Status",
      Active: true,
      Visibility: true,
      ForStatus: true,
    },
    { Name: "Name", Alias: "Name", Active: true, Visibility: true },
    {
      Name: "DistrictId",
      Alias: "DistrictId",
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "State Name", Alias: "State", Active: true, Visibility: true },
    { Name: "StateId", Alias: "StateId", Active: false, Visibility: false },
    { Name: "CreatedOn", Alias: "CreatedOn", Active: false, Visibility: false },
  ],
  DAILY_ROUTINE_ENQUIRIES: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForCaseStatus: true },
    { Name: "Date", Alias: "Date", Active: true, Visibility: true, ForDate: true },
    { Name: "Enquiry Number", Alias: "Enquiry Number", Active: true, Visibility: true },
    { Name: "Counsellor Name", Alias: "Counsellor", Active: true, Visibility: true },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    { Name: "Visa Country", Alias: "Country", Active: true, Visibility: true },
    { Name: "enquiryId", Alias: "enquiryId", Active: false, Visibility: false, ForIdentity: true },
  ],
  DAILY_ROUTINE_NEWFILE: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForCaseStatus: true },
    { Name: "Date", Alias: "Date", Active: true, Visibility: true, ForDate: true },
    {
      Name: "immigrationFileId",
      Alias: "immigrationFileId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "enquiryId", Alias: "enquiryId", Active: false, Visibility: false },
    { Name: "File Number", Alias: "File Number", Active: true, Visibility: true },
    { Name: "Counsellor Name", Alias: "Counsellor", Active: true, Visibility: true },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    { Name: "Visa Country", Alias: "Country", Active: true, Visibility: true },
  ],
  DAILY_ROUTINE_FILESTATUS: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForCaseStatus: true },
    { Name: "Date", Alias: "Date", Active: true, Visibility: true, ForDate: true },
    {
      Name: "immigrationFileId",
      Alias: "immigrationFileId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "enquiryId", Alias: "enquiryId", Active: false, Visibility: false },
    { Name: "File Number", Alias: "File Number", Active: true, Visibility: true },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    { Name: "Document Name", Alias: "Document", Active: true, Visibility: true },
    { Name: "Created By", Alias: "Created By", Active: true, Visibility: true },
  ],
  DAILY_ROUTINE_REMARKS: [
    { Name: "Action", Alias: "Action", Active: false, Visibility: false, ForAction: true },
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForCaseStatus: true },
    { Name: "Date", Alias: "Date", Active: true, Visibility: true, ForDate: true },
    {
      Name: "immigrationFileId",
      Alias: "immigrationFileId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "enquiryId", Alias: "enquiryId", Active: false, Visibility: false },
    { Name: "File Number", Alias: "File Number", Active: true, Visibility: true },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    { Name: "Remark", Alias: "Remark", Active: true, Visibility: true },
    { Name: "Created By", Alias: "Created By", Active: true, Visibility: true },
  ],
  VIEW_PAYMENT: [
    { Name: "CreatedOn", Alias: "Created On", Active: true, Visibility: true, ForDate: true },
    { Name: "Guardian Name", Alias: "Guardian", Active: true, Visibility: true },
    { Name: "Phone Number", Alias: "Phone Number", Active: true, Visibility: true },
    { Name: "Address", Alias: "Address", Active: true, Visibility: true },
    { Name: "EmailId", Alias: "Email", Active: true, Visibility: true },
    { Name: "Mode", Alias: "Mode", Active: true, Visibility: true },
    { Name: "Bank Name", Alias: "Bank", Active: true, Visibility: true },
    { Name: "Account Number", Alias: "Acc. Number", Active: true, Visibility: true },
    { Name: "Check Number", Alias: "Check Number", Active: true, Visibility: true },
    { Name: "Deposit", Alias: "Deposit", Active: true, Visibility: true },
    { Name: "Withdrawal", Alias: "Withdrawal", Active: true, Visibility: true },
    { Name: "Advance", Alias: "Advance", Active: true, Visibility: true },
    { Name: "Remark", Alias: "Remark", Active: true, Visibility: true },
  ],
  REMARK_LOGS: [
    {
      Name: "immigrationFileId",
      Alias: "immigrationFileId", 
      Active: false,
      Visibility: false,
      ForIdentity: true,
    },
    { Name: "createdOn", Alias: "Remark Date", Active: true, Visibility: true, ForDate: true },
    { Name: "userName", Alias: "User", Active: true, Visibility: true },
    { Name: "remark", Alias: "Remark", Active: true, Visibility: true },
  ],
  FILE_LOGS: [
    { Name: "Created On", Alias: "Log Date", Active: true, Visibility: true, ForDate: true },
    { Name: "Username", Alias: "User", Active: true, Visibility: true },
    { Name: "Updated On", Alias: "Updated On", Active: true, Visibility: true, ForDate: true },
    { Name: "UpdatedByUserName", Alias: "Updated By", Active: true, Visibility: true },
    { Name: "fileDocumentId", Alias: "fileDocumentId", Active: false, Visibility: false },
    { Name: "Document Name", Alias: "Document", Active: true, Visibility: true },
    { Name: "immigrationFileId", Alias: "immigrationFileId", Active: false, Visibility: false },
  ],
  DASHBOARD_LOGS: [
    { Name: "Username", Alias: "User", Active: true, Visibility: true },
    { Name: "Log Severity", Alias: "Severity", Active: true, Visibility: true },
    { Name: "Log Text", Alias: "Log", Active: true, Visibility: true },
    { Name: "Log Date", Alias: "Date", Active: true, Visibility: true, ForDate: true },
    { Name: "Log Time", Alias: "Time", Active: true, Visibility: true, ForTime: true },
  ],
  DASHBOARD_ENQUIRIES: [
    { Name: "Status", Alias: "Status", Active: true, Visibility: true, ForStatus: true },
    { Name: "Date", Alias: "Date", Active: true, Visibility: true, ForDate: true },
    { Name: "Enquiry Number", Alias: "Enquiry Number", Active: true, Visibility: true },
    { Name: "Counsellor Name", Alias: "Counsellor", Active: true, Visibility: true },
    { Name: "Applicant Name", Alias: "Applicant", Active: true, Visibility: true },
    { Name: "Visa Country", Alias: "Country", Active: true, Visibility: true },
  ],
};
