import { Box, Button, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { MENU_NAMES, USER_AUTH_TOKEN_KEY } from "../core/utils/constants";

const ProtectedRoutes = (props) => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem(USER_AUTH_TOKEN_KEY));
    if (token != null || token != undefined) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, []);

  if (isAuthorized) {
    return props.children;
  }

  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Image src="/images/error-boundary.svg" height={"600px"} />
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        {/* <Button variant={"primary"} onClick={goBack}>
          Back
        </Button> */}
           {/* <Button
              variant="primary"
              onClick={() => {
                this.props.navigate(-1, { replace: true });
              }}
            >
               Back
            </Button> */}
      </Box>
    </Box>
  );
};

export default ProtectedRoutes;
