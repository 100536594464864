import {
  Box,
  FormLabel,
  FormControl,
  InputGroup,
  Switch,
} from "@chakra-ui/react";
import React from "react";

const SwitchInput = ({
  label = {
    text: "",
    fontSize: "",
    colors: "",
    variant: "",
    display:"block"
  },
  formControl = { id: "" },
  box = { marginBottom: 5 },
  variant = "flushed",
  size = "md",
  name,
  value,
  onChange,
  isRequired,
  isReadOnly,
  isDisabled,
  disabled,
  defaultChecked = false,
}) => {
  return (
    <Box marginBottom={box.marginBottom}>
      <InputGroup size="md">
        <FormControl
          //   variant="floating"
          id={formControl.id}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          display="flex"
          alignItems="center"
        >
          <FormLabel
            fontSize={label.fontSize}
            color={label.colors}
            isRequired={isRequired}
            variant={label.variant}
            display={label.display}
          >
            {label.text}
          </FormLabel>
          <Switch
            colorScheme="yellow"
            size={size}
            disabled={disabled}
            name={name}
            value={value}
            isChecked={value}
            onChange={onChange}
            defaultChecked={defaultChecked}
          />
        </FormControl>
      </InputGroup>
    </Box>
  );
};

export default SwitchInput;
