import React, { useEffect, useState } from "react";

const useTableHeightFix = () => {
  const [windowHeight, setWindowHeight] = useState(0);
  const [fHeight, setFHeight] = useState(0);

  function resizeElementHeight(element) {
    var height = 0;
    var sum = 0;
    var body = window.document.body;
    const breadCrumb = "chakra-breadcrumb",
      topBar = "top-bar",
      pagination = "pagination-wrapper";
    const classesArr = [topBar, breadCrumb, pagination];
    classesArr.map((cls) => {
      if (document.getElementsByClassName(cls)[0]?.clientHeight)
        sum += document.getElementsByClassName(cls)[0].clientHeight;
    });

    if (window.innerHeight) {
      height = window.innerHeight;
    } else if (body.parentElement.clientHeight) {
      height = body.parentElement.clientHeight;
    } else if (body && body.clientHeight) {
      height = body.clientHeight;
    }

    element.style.height = "calc(100vh - 331px)";
    element.style.overflowY = "auto";
    setFHeight(element.style.height);
  }

  const updateWindowDimensions = () => {
    const newHeight = window.innerHeight;
    setWindowHeight(newHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);

  return [fHeight, resizeElementHeight, windowHeight];
};

export default useTableHeightFix;
