import { WarningAlert } from "./common-sweet-alert";
import { ERROR_CODES } from "./constants";

const handleError = (error) => {
  if (error.response.status === 404) {
    WarningAlert("Something went wrong");
    document.body.classList.remove("loader");
    document.getElementById("loader").style.display = "none";
    return;
  }

  for (let code in ERROR_CODES) {
    if (error.response.status === ERROR_CODES[code]) {
      if (
        error.response.data.message != "" &&
        error.response.data.message != null
      )
        WarningAlert(error.response.data.message);
      document.body.classList.remove("loader");
      document.getElementById("loader").style.display = "none";
      return;
    }
  }
};

export default handleError;
