import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const DeleteModal = ({ isOpen, onClose, onDelete, title }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant="wide"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottom={"1px solid #EDEDED"}>Delete</ModalHeader>
        <ModalCloseButton
          backgroundColor={"rgba(230, 21, 21, 0.18)"}
          color={"#E61515"}
          position={"absolute"}
          top={"15px"}
        />
        <ModalBody
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Image src="/images/deleted 1.png"></Image>
        </ModalBody>
        <Heading
          size="sm"
          textAlign={"center"}
          fontSize={"24px"}
          fontWeight={"700"}
        >
          {title ? title : "Are you sure you want to delete?"}
        </Heading>
        <ModalFooter display={"flex"} justifyContent={"center"}>
          <Button
            variant={"with-shadow"}
            onClick={onDelete}
            marginBottom={"30px"}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
