import {
  Box,
  FormLabel,
  FormControl,
  InputGroup,
  Select,
  InputRightElement,
  Button,
  Icon,
  Image,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";

const SelectInput = ({
  label = {
    text: "",
    fontSize: "",
    colors: "",
    variant: "",
    width: "",
  },
  value,
  formControl = { id: "", variant: "" },
  box = { marginBottom: 5 },
  variant = "flushed",
  name,
  placeholder,
  onChange,
  isRequired,
  isLoading,
  isReadOnly,
  width,
  options = [],
}) => {
  const labelProps = {
    ...(!!value && { transform: "scale(0.85) translateY(-24px)!important" }),
  };
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = () => {
    setIsVisible((_) => !_);
  };
  return (
    <Box marginBottom={box.marginBottom}>
      <InputGroup size="md">
        <FormControl
          variant={formControl.variant}
          id={formControl.id}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          _readOnly={isLoading}
        >
          <FormLabel
            fontSize={label.fontSize}
            color={label.colors}
            isRequired={isRequired}
            variant={label.variant}
            {...labelProps}
          >
            {label.text}
          </FormLabel>
          <Select
            placeholder={placeholder}
            id={formControl.id}
            name={name}
            variant={variant}
            onChange={onChange}
            value={value}
            isDisabled={isReadOnly}
            isReadOnly={isReadOnly}
            width={width}
          >
            <option value="" hidden></option>
            {options.map((option, key) => (
              <option key={key} value={option.value}>
                {option.text}
              </option>
            ))}
          </Select>
        </FormControl>
        {Icon.visible && (
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {isVisible ? (
                <FaPlus />
              ) : (
                <Image width={"30px"} src="/images/eye-close.svg" />
              )}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};

export default SelectInput;
