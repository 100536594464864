import { GET_LOGIN_USER_DATA, ROUTE_PERMISSIONS, SIGN_IN, SIGN_OUT } from "../../utils/constants";

const initialState = { login: {} };
const authReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {

    case SIGN_IN: {
      return {
        ...state,
        login: payload,
      };
    }

    case SIGN_OUT: {
      return {
        ...state,
        login: payload,
      };
    }

    case GET_LOGIN_USER_DATA: {
      return {
        ...state,
        login: payload,
      };
    }
    
    case ROUTE_PERMISSIONS: {
      return {
        ...state,
        permission: payload
      }
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
