import moment from "moment";
import api from "../api";
import {
  FILTER_ENUMS,
  EXPORT_FILE_TYPE,
  EXPORT_FILE_EXTENSION,
  ROLES,
} from "./constants";
import { SuccessAlert } from "./common-sweet-alert";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { colors } from "../../theme/styles/colors";

export function PARSE_JWT(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const getById = async (module, id) => {
  try {
    const response = await api.get(`${module}/get/${id}`);
    return { status: response.status, data: response.data.data };
  } catch (error) {
    return { error: error, data: false };
  }
};

export const deleteById = async (module, id) => {
  try {
    const response = await api.delete(`${module}/delete/${id}`);
    SuccessAlert("Record Deleted Successfully");
    return { status: response.status, data: response.data.data };
  } catch (error) {
    return { error: error, data: false };
  }
};

export const getUsersByRole = async (roleID, branchID = null) => {
  try {
    const response = await api.get(`User/getUsersByRole/${roleID}/${branchID}`);
    return { status: response.status, data: response.data.data };
  } catch (error) {
    return { error: error, data: false };
  }
};

export const getAll = async (module, payload) => {
  try {
    const response = await api.post(`${module}/getAll`, payload);
    return { status: response.status, data: response.data.data };
  } catch (error) {
    return { error: error, data: false };
  }
};

export const handleResetState = (state, setState) => {
  const obj = {};
  if (Object.keys(state).length > 0) {
    for (let key in state) {
      if (state[key] && Object.keys(state[key]).length > 0) {
        for (let innerKey in state[key])
          obj[key] = {
            ...obj[key],
            [innerKey]: "",
          };
      } else {
        obj[key] = state[key] == null ? null : "";
      }
    }
    setState(obj);
  }
};

/**
 * for string Contain
 * for date range Between
 * for date Equal to
 * for boolean Equal to
 */
export const checkOperator = (type) => {
  const containArr = ["text", "email", "search"];
  const equalArr = [
    "number",
    "month",
    "tel",
    "boolean",
    "select-one",
    "date",
    "datetime-local",
    "checkbox",
  ];

  if (containArr.includes(type.toLowerCase())) return FILTER_ENUMS.Contains;

  if (equalArr.includes(type)) return FILTER_ENUMS.Equals;
};

export const handleFilterChange = (e, state, setState) => {
  const { name, value, type } = e.target;
  let FType = type;
  const operatorValue = checkOperator(type);
  if (type === "search") {
    FType = "text";
  } else if (
    type === "select-one" &&
    [true, false, "true", "false"].includes(value)
  ) {
    FType = "boolean";
  } else if (type === "select-one" && !isNaN(value)) {
    /* 
    isNaN checks if a value is not a number. 
    It returns true if the value is not, and 
    false if it’s a number. 
  */
    FType = "number";
  } else if (type === "select-one" && isNaN(value)) {
    FType = "text";
  } else if (type === "date") {
    FType = "datetime";
  }

  setState({
    ...state,
    [name]: {
      value,
      propertyName: name,
      operator: operatorValue,
      type: FType,
    },
  });
};

export const handleCheckboxFilterChange = (e, state, setState) => {
  const { name, checked } = e.target;
  setState({
    ...state,
    [name]: {
      checked: checked,
      //value: checked,
      propertyName: name,
      // operator: FILTER_ENUMS.Equals,
      type: "datetime",
      isGrouped: true,
    },
  });
};

export const createFilterPayload = (filterState) => {
  let payloadArr = [];
  if (Object.keys(filterState).length > 0) {
    for (let key in filterState) {
      if (
        filterState[key] != null &&
        filterState[key] != "" &&
        (filterState[key].checked || filterState[key].value != "")
      ) {
        if (
          !filterState[key].isGrouped &&
          (!filterState[key].propertyName.includes("fromDate") ||
            !filterState[key].propertyName.includes("toDate"))
        )
          payloadArr.push({
            value: filterState[key].value,
            propertyName: filterState[key].propertyName,
            operator: filterState[key].operator,
            type: filterState[key].type,
          });
        if (filterState[key].isGrouped)
          payloadArr.push({
            value: filterState[key].value,
            propertyName: filterState[key].propertyName,
            operator: filterState[key].operator,
            type: filterState[key].type,
            isGrouped: filterState[key].isGrouped,
            checked: filterState[key].checked,
          });
      }
    }
  }
  if (filterState["fromDate"] && filterState["toDate"]) {
    const createdOn = `${filterState["fromDate"].value},${filterState["toDate"].value}`;
    payloadArr.push({
      value: createdOn,
      propertyName: "CreatedOn",
      operator: FILTER_ENUMS.Equals,
      type: "DateRange",
    });
  }

  const filters = payloadArr.filter(
    (x) => !["fromDate", "toDate"].includes(x.propertyName)
  );
  return filters;
};

export const getFilterCount = (filterState) => {
  let sum = 0;
  if (Object.keys(filterState).length > 0) {
    for (let key in filterState) {
      if (
        filterState[key] != null &&
        filterState[key] != "" &&
        (filterState[key].checked || filterState[key].value != "")
      ) {
        sum += 1;
      }
    }
  }
  return sum;
};
export const convertDateFormat = function (value, format) {
  var d = new Date(value);
  var dd = d.getDate();

  var mm = d.getMonth() + 1;
  var yyyy = d.getFullYear();

  var HH = 0 + d.getHours();
  var MM = 0 + d.getMinutes();

  if (MM < 60) {
    MM = 60 - MM;
    HH = HH + 1;
  }

  if (MM === 60) {
    MM = "00";
  }

  if (MM < 10) {
    MM = "0" + MM;
  }

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  if (format === "yyyy-mm-dd") {
    d = yyyy + "-" + mm + "-" + dd;
  } else if (format === "yyyy/mm/dd") {
    d = yyyy + "/" + mm + "/" + dd;
  } else if (format === "dd-mm-yyyy") {
    d = dd + "-" + mm + "-" + yyyy;
  } else if (format === "hh:mm") {
    d = HH + ":" + MM;
  } else if (format === "yyyy-mm-dd HH:MM") {
    d = moment(d).format("YYYY-MM-DD h:mm");
  } else if (format === "dd/mm/yyyy") {
    d = dd + "/" + mm + "/" + yyyy;
  }

  return d;
};

export const EXPORT_TO_EXCEL = async (fileName, headers, fileData) => {
  headers = headers.filter((x) => x.Active && x.Visibility);
  fileData = CREATE_RESPONSE(headers, fileData, true);
  headers = headers.map((column) => {
    return column.Alias;
  });
  const ws = XLSX.utils.json_to_sheet(fileData);
  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: EXPORT_FILE_TYPE });
  FileSaver.saveAs(data, fileName + EXPORT_FILE_EXTENSION);
};

const badgeColors = [
  {
    id: 1,
    text: "Closed",
    textColour: colors.badge.red,
    bgColour: colors.badge.redBg,
  },
  {
    id: 2,
    text: "Converted To File",
    textColour: colors.badge.green,
    bgColour: colors.badge.greenBg,
  },
  {
    id: 3,
    text: "Enrolled",
    textColour: colors.badge.lightpurple,
    bgColour: colors.badge.lightpurpleBg,
  },
  {
    id: 4,
    text: "Interested",
    textColour: colors.badge.lightBlue,
    bgColour: colors.badge.lightBlueBg,
  },
  {
    id: 5,
    text: "Lost",
    textColour: colors.badge.lost,
    bgColour: colors.badge.lostBg,
  },
  {
    id: 6,
    text: "Neutral",
    textColour: colors.badge.violet,
    bgColour: colors.badge.violetBg,
  },
  {
    id: 7,
    text: "Not Interested",
    textColour: colors.badge.skyBlue,
    bgColour: colors.badge.skyBlueBg,
  },
  {
    id: 8,
    text: "Not Eligible",
    textColour: colors.badge.lightPink,
    bgColour: colors.badge.lightPinkBg,
  },
  {
    id: 9,
    text: "Phone Not-Picked",
    textColour: colors.badge.darkpurple,
    bgColour: colors.badge.darkpurpleBg,
  },
  {
    id: 10,
    text: "Phone Switch Off",
    textColour: colors.badge.orange,
    bgColour: colors.badge.orangeBg,
  },
];

export const getBadgeColor = (id) => {
  return badgeColors.filter((color) => color.colorId === id);
};

export const getEnquiryStatusColour = (name) => {
  return badgeColors.find((color) => color.text == name.trim());
};

const caseStatusColours = [
  {
    id: 1,
    text: "Active",
    textColour: colors.badge.green,
    bgColour: colors.badge.greenBg,
  },
  {
    id: 2,
    text: "Closed",
    textColour: colors.badge.red,
    bgColour: colors.badge.redBg,
  },
  {
    id: 3,
    text: "File On-Hold",
    textColour: colors.badge.violet,
    bgColour: colors.badge.violetBg,
  },
  {
    id: 4,
    text: "Visa Granted",
    textColour: colors.badge.sky,
    bgColour: colors.badge.skyBg,
  },
  {
    id: 5,
    text: "Visa Refused",
    textColour: colors.badge.blue,
    bgColour: colors.badge.blueBg,
  },
];

export const fileTypeColors = [
  {
    id: 1,
    text: "Normal",
    textColour: colors.badge.green,
    bgColour: colors.badge.greenBg,
  },
  {
    id: 2,
    text: "Urgent",
    textColour: colors.badge.red,
    bgColour: colors.badge.redBg,
  },
];

export const followUpColors = [
  {
    id: 1,
    text: "No Followup",
    textColour: colors.badge.amber,
    bgColour: colors.badge.amber,
  },
  {
    id: 2,
    text: "Pending Followup",
    textColour: colors.badge.indigo,
    bgColour: colors.badge.indigo,
  },
  {
    id: 3,
    text: "Today Followup",
    textColour: colors.badge.lilac,
    bgColour: colors.badge.lilac,
  },
  {
    id: 4,
    text: "Future Followup",
    textColour: colors.badge.purpleBlue,
    bgColour: colors.badge.purpleBlue,
  },
];

export const getCaseStatusColour = (name) => {
  return caseStatusColours.find((color) => color.text == name.trim());
};
export const getFileTypeColour = (name) => {
  return fileTypeColors.find((color) => color.text == name.trim());
};

export const checkPermission = (routePermission, item = null, route = null) => {
  let permission = {};
  if (item) permission = routePermission.find((x) => x.name == item.label);
  if (!item) {
    route = route.split("/")[route.split("/").length - 1];
    permission = routePermission.find((x) => x.route == route);
  }

  if (permission == undefined || permission == null) return false;
  return item ? permission.hasView : permission;
};

export const getFollowupColor = (date) => {
  const today = convertDateFormat(new Date(), "dd-mm-yyyy");
  const convertedDate = convertDateFormat(date, "dd-mm-yyyy");

  // no followup
  if (!convertedDate || convertedDate === "" || convertedDate == null) {
    return followUpColors.find((x) => x.id == 1);
  }
  // pending followup
  if (convertedDate < today) {
    return followUpColors.find((x) => x.id == 2);
  }

  //today followup
  if (convertedDate == today) {
    return followUpColors.find((x) => x.id == 3);
  }

  //future followup
  if (convertedDate > today) {
    return followUpColors.find((x) => x.id == 4);
  }
};

export const CREATE_RESPONSE = (
  columns = [],
  jsonData = [],
  forExport = false
) => {
  let result = [];

  jsonData.forEach((item, index) => {
    let obj = {};
    const itemKeys = Object.keys(item);
    const columnKeys = columns.map((x) => x.Name);

    columnKeys.map((key) => {
      const column = columns.find((x) => x.Name == key);
      const dKey = itemKeys.find(
        (x) =>
          x.replace(/ /g, "").toLowerCase() ==
          key.replace(/ /g, "").toLowerCase()
      );

      if (column != undefined) {
        obj[column.Name] = item[dKey];

        /// TO PERFORM EDIT/VIEW/DELETE OPERATION
        if (column.ForIdentity) obj["Id"] = item[dKey];

        /// TO PERFORM DATE FORMAT CONVERSION
        if (column.ForDate && item[dKey] != null)
          obj[column.Name] = forExport
            ? item[dKey]
            : convertDateFormat(item[dKey], "dd-mm-yyyy");

        /// IMMIGRATION/ENQUIRY: TO PERFORM STATUS FORMAT CONVERSION
        if (
          forExport &&
          column.ForCaseStatus != undefined &&
          typeof item[dKey] === "object"
        )
          obj[column.Name] = item[dKey].text;
      }

      if (!forExport) {
        /// TO SETUP BADGE STATUS
        if (item["isActive"]) obj["Status"] = item["isActive"];

        /// IMMIGRATION: TO SETUP BADGE STATUS/PILL
        if (item["caseStatusName"])
          obj["Status"] = getCaseStatusColour(item["caseStatusName"]);
        if (item["fileModeName"])
          obj["Mode"] = getFileTypeColour(item["fileModeName"]);

        /// ENQUIRY: TO SETUP BADGE STATUS/PILL
        if (item["enquiryStatusName"])
          obj["Status"] = getEnquiryStatusColour(item["enquiryStatusName"]);
        if (item["followUpDate"])
          obj["Mode"] = getFollowupColor(item["followUpDate"]);

        /// ROLE: TO SETUP ROLE NAME BASED ON ROLE-ID
        if (item["roleId"]) obj["Role"] = GET_ROLE_NAME(item["roleId"]);
      }
    });

    if (Object.keys(obj).length) result.push(obj);
  });

  return result;
};

export const GET_ROLE_NAME = (id) => {
  let result = "";
  switch (id) {
    case ROLES.ADMINISTRATOR:
      result = "Administrator";
      break;
    case ROLES.ACCOUNTANT:
      result = "Accountant";
      break;
    case ROLES.BRANCH_HEAD:
      result = "Branch Head";
      break;
    case ROLES.COUNSELLOR:
      result = "Counsellor";
      break;
    case ROLES.TELE_CALLER:
      result = "Telecaller";
      break;
    case ROLES.FILLING_HEAD:
      result = "Filling Head";
      break;
  }
  return result;
};

export const GET_MENU_NAME = (menus, menuId) => {
  return menus.find((x) => x.moduleId == menuId).name;
};

export const GRP_MENU_WITH_CHILD = (menus) => {
  const result = menus.reduce((item, org) => {
    let hasChild = false;
    if (org.parentId == null) {
      hasChild = menus.filter((x) => x.parentId == org.moduleId).length > 0;
    }

    const key = hasChild
      ? org.moduleId
      : org.parentId == null
      ? 0
      : org.parentId;
    item[key] = item[key] || [];
    item[key].push(org);

    return item;
  }, []);
  return result;
};
