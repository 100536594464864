import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Tbody,
  TableContainer,
  Td,
  Th,
  Tr,
  Table,
  Thead,
  SimpleGrid,
  Collapse,
  Button,
  Text,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { colors } from "../../../theme/styles/colors";
import BreadcrumbBox from "../../../theme/components/breadcrumb";
import TextInput from "../../../theme/components/input/text-input";
import Pagination from "../../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../../core/utils/navbar-items";
import { MENU_NAMES, PAGE_SIZE, ROLES } from "../../../core/utils/constants";
import { GRID_COLUMNS } from "../../../core/utils/grid-columns";
import { BiExport } from "react-icons/bi";
import SelectInput from "../../../theme/components/input/select-input";
import { GoFilter } from "react-icons/go";
import MultiSelectInput from "../../../theme/components/input/multiselect-input";
import useTableHeightFix from "../../../core/hooks/useTableHeightFix";
import {
  EXPORT_TO_EXCEL,
  getFilterCount,
  convertDateFormat,
  createFilterPayload,
  getAll,
  handleFilterChange,
  handleResetState,
  getUsersByRole,
  CREATE_RESPONSE,
} from "../../../core/utils/common-function";
import api from "../../../core/api";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_LINKS } from "../../../core/utils/route-links";
import Grid from "../../../theme/components/grid/grid";

const FeedbackReport = (props) => {
  const selector = useSelector((_) => _);
  const navigate = useNavigate();
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [branchId, setBranchId] = useState(selector.branch.userBranchId);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [minToDate, setMinToDate] = useState(
    convertDateFormat(new Date(), "yyyy-mm-dd")
  );
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.FEEDBACK_REPORT.filter((x) => x.Active && x.Visibility)
  );
  const [isToDate, setIsToDate] = useState(true);
  const [isView, setIsView] = useState(false);
  const [initialState, setInitialState] = useState({
    name: "",
    isActive: false,
  });
  const [filterState, setFilterState] = useState({
    Name: "",
    FromDate: "",
    ToDate: "",
    CounsellorId: "",
  });
  const [branch, setBranch] = useState([]);
  const [feedbackReportId, setfeedbackReportId] = useState(null);
  const [show, setShow] = useState(false);
  const [tableRowData, setTableRowData] = useState([]);
  const [counsellorState, setCounsellorState] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [state, setState] = useState();
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };
  const handleToggle = () => {
    setShow(!show);
  };
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
    branchId: selector.branch.userBranchId,
  });
  const [activeFilter, setActiveFilter] = useState(0);
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const getAllRecords = () => {
    api
      .post("/Enquiry/getFeedbackReport", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(
          CREATE_RESPONSE(GRID_COLUMNS.FEEDBACK_REPORT, res.data.data.data)
        );
      })
      .catch((err) => {});
  };

  const getAllPromises = () => {
    // const Branch = getAll("Branch", payload);
    const getCounsellor = getUsersByRole(
      ROLES.COUNSELLOR,
      selector.branch.userBranchId
    );

    Promise.all([getCounsellor])
      .then((res) => {
        const [counsellor] = res;
        const counsellorResp = counsellor.data.map((counsler) => ({
          text: counsler.name,
          value: counsler.userId,
        }));
        setCounsellorState(counsellorResp);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
    // getAllRecords();
  };
  useEffect(() => {
    document.title = "Feedback Report | Digital Filing";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllRecords();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    if (selector.branch.userBranchId) {
      setBranchId(selector.branch.userBranchId);
      setPayload({
        ...payload,
        branchId: selector.branch.userBranchId,
      });
      getAllPromises();
    }
  }, [selector.branch.userBranchId]);

  useEffect(() => {
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);

  return (
    <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
      <Box>
        <BreadcrumbBox
          route={GET_BREADCRUMB_ROUTES(MENU_NAMES.FEEDBACK_REPORT)}
        />
      </Box>
      <Box
        color={colors.white}
        display={"flex"}
        justifyContent="space-between"
        alignItems={"center"}
        marginBottom={"20px"}
      >
        <Box>
          <Heading variant={"h1"}>Feedback Report</Heading>
        </Box>
        <Button onClick={handleToggle} variant={"secondary"}>
          <GoFilter fontSize={"20px"} />
          <Text marginLeft={"5px"} className="btnText ">
            Filter
          </Text>
          <Box
            position={"absolute"}
            top={"-19px"}
            right={"-1"}
            backgroundColor={colors.primaryColor}
            color={colors.white}
            borderRadius={"16px"}
            padding={"0px"}
            height={"30px"}
            width={"30px"}
            lineHeight={"30px"}
          >
            {activeFilter}
          </Box>
        </Button>
      </Box>
      <Box
        backgroundColor={colors.white}
        boxShadow={"0px 0px 35px 0px #F5F5F5"}
        borderRadius={"10px"}
        marginBottom={"20px"}
      >
        <Collapse in={show}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const payloadArr = createFilterPayload(filterState);
              const filterSum = getFilterCount(filterState);
              setActiveFilter(filterSum);
              setAPIPayload({
                ...apiPayload,
                filter: payloadArr,
                pageSize: PAGE_SIZE,
                pageIndex: 1,
                branchId: selector.branch.userBranchId,
              });
            }}
          >
            <SimpleGrid
              columns={{ base: "1", sm: "1", md: "4", lg: "4" }}
              spacingX="40px"
              spacingY="20px"
              padding={"20px"}
              paddingBottom={"0px"}
            >
              <TextInput
                type="text"
                name="Name"
                value={filterState.Name?.value}
                onChange={(e) =>
                  handleFilterChange(e, filterState, setFilterState)
                }
                formControl={{ id: "Name" }}
                placeholder={""}
                variant="flushed"
                label={{ text: "Name" }}
              />
              <TextInput
                type="date"
                name="fromDate"
                value={filterState.fromDate?.value}
                onChange={(e) => {
                  let D = new Date(e.target.value).setDate(
                    new Date(e.target.value).getDate() + 1
                  );
                  setMinToDate(convertDateFormat(D, "yyyy-mm-dd"));
                  handleFilterChange(e, filterState, setFilterState);
                  if (e.target.value != "") {
                    setIsToDate(false);
                  }
                }}
                placeholder={""}
                variant="flushed"
                label={{ text: "From" }}
              />
              <TextInput
                type="date"
                isDisabled={isToDate}
                minVal={minToDate}
                name="toDate"
                value={filterState.toDate?.value}
                onChange={(e) =>
                  handleFilterChange(e, filterState, setFilterState)
                }
                placeholder={""}
                variant="flushed"
                label={{ text: "To" }}
              />
              <SelectInput
                formControl={{ id: "CounsellorId", variant: "floating" }}
                isRequired={false}
                isReadOnly={false}
                name="CounsellorId"
                value={filterState.CounsellorId?.value}
                onChange={(e) =>
                  handleFilterChange(e, filterState, setFilterState)
                }
                label={{
                  text: "Counsellor",
                }}
                options={counsellorState}
              />
            </SimpleGrid>
            <Box
              display={"flex"}
              justifyContent={"end"}
              paddingRight={"20px"}
              paddingBottom={"20px"}
            >
              <Button
                fontSize={{ base: "12px", sm: "13px", md: "14px", lg: "14px" }}
                variant={"primary"}
                type="submit"
              >
                Search
              </Button>
              <Button
                fontSize={{ base: "12px", sm: "13px", md: "14px", lg: "14px" }}
                variant={"warning"}
                marginLeft={"10px"}
                isDisabled={activeFilter > 0 ? false : true}
                onClick={resetFilterState}
              >
                Reset
              </Button>
            </Box>
          </form>
        </Collapse>
      </Box>
      <Grid
        name={"feedbackReport"}
        columns={GRID_COLUMNS.FEEDBACK_REPORT}
        aColumns={columns}
        data={data}
        permission={props}
        hasExport={hasExport}
        hasEdit={false}
        setColumns={(e) => setColumns(e)}
        viewRoute={ROUTE_LINKS[MENU_NAMES.EDIT_VIEW_ENQUIRY]}
      />
      <Pagination
        className="pagination-bar"
        currentPage={apiPayload.pageIndex}
        totalCount={totalCount}
        pageSize={apiPayload.pageSize}
        onPageChange={(page) =>
          setAPIPayload({
            ...apiPayload,
            pageIndex: page,
          })
        }
        handleRowStateChange={(e) => {
          setAPIPayload({
            ...apiPayload,
            pageIndex: 1,
            pageSize: e.target.value,
          });
        }}
        activeBackgroundColor={colors.primaryColor}
        activeTextColor={colors.gray.gray100}
        onChange={onChange}
      />
    </Container>
  );
};

export default FeedbackReport;
