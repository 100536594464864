import { MENU_NAMES } from "./constants";
import { ROUTE_LINKS } from "./route-links";
import { RxDashboard } from "react-icons/rx";
import { BsAirplane } from "react-icons/bs";
import { CgToolbox } from "react-icons/cg";
import { colors } from "../../theme/styles/colors";
import { AiOutlineBranches } from "react-icons/ai";
import { GoShieldLock } from "react-icons/go";
import { TbRecycle } from "react-icons/tb";
import { BsFileEarmarkText } from "react-icons/bs";
const DASHBOARD_OBJ = {
  _id: 0,
  label: MENU_NAMES.DASHBOARD,
  path: ROUTE_LINKS[MENU_NAMES.DASHBOARD],
  icon: <RxDashboard color={colors.primaryColor} fontSize={"24px"} />,
  parent: "",
  active: true,
};

const ENQUIRY = {
  _id: 1,
  label: MENU_NAMES.ENQUIRY,
  path: ROUTE_LINKS[MENU_NAMES.ENQUIRY],
  icon: <CgToolbox color={colors.primaryColor} fontSize={"24px"} />,
  parent: DASHBOARD_OBJ,
  active: true,
};

const IMMIGRATION = {
  _id: 2,
  label: MENU_NAMES.IMMIGRATION,
  path: ROUTE_LINKS[MENU_NAMES.IMMIGRATION],
  icon: <BsAirplane color={colors.primaryColor} fontSize={"24px"} />,
  parent: DASHBOARD_OBJ,
  active: true,
};

const BRANCH = {
  _id: 3,
  label: MENU_NAMES.BRANCH,
  path: ROUTE_LINKS[MENU_NAMES.BRANCH],
  icon: <AiOutlineBranches color={colors.primaryColor} fontSize={"24px"} />,
  parent: DASHBOARD_OBJ,
  active: true,
};

const ACCOUNT = {
  _id: 4,
  label: MENU_NAMES.ACCOUNT,
  path: ROUTE_LINKS[MENU_NAMES.ACCOUNT],
  icon: <GoShieldLock color={colors.primaryColor} fontSize={"24px"} />,
  parent: DASHBOARD_OBJ,
  active: true,
  isRouteable: false,
};

const GENERAL = {
  _id: 5,
  label: MENU_NAMES.GENERAL,
  path: ROUTE_LINKS[MENU_NAMES.GENERAL],
  icon: <TbRecycle color={colors.primaryColor} fontSize={"24px"} />,
  parent: DASHBOARD_OBJ,
  active: true,
  isRouteable: false,
};

const DAILY_ROUTINE_OBJ = {
  _id: 6,
  label: MENU_NAMES.DAILY_ROUTINE,
  path: ROUTE_LINKS[MENU_NAMES.DAILY_ROUTINE],
  icon: <BsFileEarmarkText color={colors.primaryColor} fontSize={"24px"} />,
  parent: DASHBOARD_OBJ,
  active: true,
};

const USER = {
  label: MENU_NAMES.USER,
  path: ROUTE_LINKS[MENU_NAMES.GENERAL_USER],
  parent: GENERAL,
  active: true,
};

const ROLE = {
  label: MENU_NAMES.ROLE,
  path: ROUTE_LINKS[MENU_NAMES.GENERAL_ROLE],
  parent: GENERAL,
  active: true,
};

const ENQUIRY_CHILDREN = [
  {
    label: MENU_NAMES.FEEDBACK_REPORT,
    path: ROUTE_LINKS[MENU_NAMES.FEEDBACK_REPORT],
    parent: ENQUIRY,
    active: true,
  },
  {
    label: MENU_NAMES.ENQUIRY_STATUS,
    path: ROUTE_LINKS[MENU_NAMES.ENQUIRY_STATUS],
    parent: ENQUIRY,
    active: true,
  },
  {
    label: MENU_NAMES.BOARD,
    path: ROUTE_LINKS[MENU_NAMES.BOARD],
    parent: ENQUIRY,
    active: true,
  },
  {
    label: MENU_NAMES.QUAL_STREAM,
    path: ROUTE_LINKS[MENU_NAMES.QUAL_STREAM],
    parent: ENQUIRY,
    active: true,
  },
  {
    label: MENU_NAMES.REFERENCE,
    path: ROUTE_LINKS[MENU_NAMES.REFERENCE],
    parent: ENQUIRY,
    active: true,
  },
  {
    label: MENU_NAMES.ADD_ENQUIRY,
    path: ROUTE_LINKS[MENU_NAMES.ADD_ENQUIRY],
    parent: ENQUIRY,
    active: false,
  },
  {
    label: MENU_NAMES.EDIT_ENQUIRY,
    path: ROUTE_LINKS[MENU_NAMES.EDIT_ENQUIRY],
    parent: ENQUIRY,
    active: false,
  },
  {
    label: MENU_NAMES.VIEW_ENQUIRY,
    path: ROUTE_LINKS[MENU_NAMES.VIEW_ENQUIRY],
    parent: ENQUIRY,
    active: false,
  },
];
const IMMIGRATION_CHILDREN = [
  {
    label: MENU_NAMES.PENDING_WORK,
    path: ROUTE_LINKS[MENU_NAMES.PENDING_WORK],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.PENDING_DOCUMENT,
    path: ROUTE_LINKS[MENU_NAMES.PENDING_DOCUMENT],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.VISA_TYPE,
    path: ROUTE_LINKS[MENU_NAMES.VISA_TYPE],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.VISA_COUNTRY,
    path: ROUTE_LINKS[MENU_NAMES.VISA_COUNTRY],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.VISA_CITY,
    path: ROUTE_LINKS[MENU_NAMES.VISA_CITY],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.VISA_COLLEGE,
    path: ROUTE_LINKS[MENU_NAMES.VISA_COLLEGE],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.VISA_COURSE,
    path: ROUTE_LINKS[MENU_NAMES.VISA_COURSE],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.FILE_STATUS,
    path: ROUTE_LINKS[MENU_NAMES.FILE_STATUS],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.FILE_TYPE,
    path: ROUTE_LINKS[MENU_NAMES.FILE_TYPE],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.FILE_MODE,
    path: ROUTE_LINKS[MENU_NAMES.FILE_MODE],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.FILE_DOCUMENT,
    path: ROUTE_LINKS[MENU_NAMES.FILE_DOCUMENT],
    parent: IMMIGRATION,
    active: true,
  },
  {
    label: MENU_NAMES.EDIT_IMMIGRATION,
    path: ROUTE_LINKS[MENU_NAMES.EDIT_IMMIGRATION],
    parent: IMMIGRATION,
    active: false,
  },
  {
    label: MENU_NAMES.VIEW_IMMIGRATION,
    path: ROUTE_LINKS[MENU_NAMES.VIEW_IMMIGRATION],
    parent: IMMIGRATION,
    active: false,
  },
  {
    label: MENU_NAMES.ADD_IMMIGRATION,
    path: ROUTE_LINKS[MENU_NAMES.ADD_IMMIGRATION],
    parent: IMMIGRATION,
    active: false,
  },
  {
    label: MENU_NAMES.VIEW_PAYMENT,
    path: ROUTE_LINKS[MENU_NAMES.VIEW_PAYMENT],
    parent: IMMIGRATION,
    active: false,
  },
  {
    label: MENU_NAMES.ADD_PAYMENT,
    path: ROUTE_LINKS[MENU_NAMES.ADD_PAYMENT],
    parent: IMMIGRATION,
    active: false,
  },
];

const BRANCH_CHILDREN = [
  {
    label: MENU_NAMES.ADD_BRANCH,
    path: ROUTE_LINKS[MENU_NAMES.ADD_BRANCH],
    parent: BRANCH,
    active: false,
  },
  {
    label: MENU_NAMES.VIEW_PERMISSIONS,
    path: ROUTE_LINKS[MENU_NAMES.VIEW_PERMISSIONS],
    parent: BRANCH,
    active: false,
  },
];
const ACCOUNT_CHILDREN = [
  {
    label: MENU_NAMES.FUND_TYPE,
    path: ROUTE_LINKS[MENU_NAMES.FUND_TYPE],
    parent: ACCOUNT,
    active: true,
  },
  {
    label: MENU_NAMES.GRADE,
    path: ROUTE_LINKS[MENU_NAMES.GRADE],
    parent: ACCOUNT,
    active: true,
  },
  {
    label: MENU_NAMES.BANK,
    path: ROUTE_LINKS[MENU_NAMES.BANK],
    parent: ACCOUNT,
    active: true,
  },
];
const GENERAL_CHILDREN = [
  USER,
  ROLE,
  {
    label: MENU_NAMES.MODULE,
    path: ROUTE_LINKS[MENU_NAMES.MODULE],
    parent: GENERAL,
    active: true,
  },
  {
    label: MENU_NAMES.STATE,
    path: ROUTE_LINKS[MENU_NAMES.STATE],
    parent: GENERAL,
    active: true,
  },
  {
    label: MENU_NAMES.DISTRICT,
    path: ROUTE_LINKS[MENU_NAMES.DISTRICT],
    parent: GENERAL,
    active: true,
  },
];
const USER_CHILDREN = [
  {
    label: MENU_NAMES.ADD_USER,
    path: ROUTE_LINKS[MENU_NAMES.ADD_USER],
    parent: USER,
    active: false,
  },
  {
    label: MENU_NAMES.EDIT_USER,
    path: ROUTE_LINKS[MENU_NAMES.EDIT_USER],
    parent: USER,
    active: false,
  },
  {
    label: MENU_NAMES.VIEW_USER,
    path: ROUTE_LINKS[MENU_NAMES.VIEW_USER],
    parent: USER,
    active: false,
  },
];
const ROLE_CHILDREN = [
  {
    label: MENU_NAMES.ADD_ROLE,
    path: ROUTE_LINKS[MENU_NAMES.ADD_ROLE],
    parent: ROLE,
    active: false,
  },
  {
    label: MENU_NAMES.EDIT_ROLE,
    path: ROUTE_LINKS[MENU_NAMES.EDIT_ROLE],
    parent: ROLE,
    active: false,
  },
  {
    label: MENU_NAMES.VIEW_ROLE,
    path: ROUTE_LINKS[MENU_NAMES.VIEW_ROLE],
    parent: ROLE,
    active: false,
  },
];

const ENQUIRY_OBJ = {
  ...ENQUIRY,
  children: ENQUIRY_CHILDREN.filter((child) => child.active == true),
};
const IMMIGRATION_OBJ = {
  ...IMMIGRATION,
  children: IMMIGRATION_CHILDREN.filter((child) => child.active == true),
};
const BRANCH_OBJ = {
  ...BRANCH,
};
const ACCOUNT_OBJ = {
  ...ACCOUNT,
  children: ACCOUNT_CHILDREN.filter((child) => child.active == true),
};
const GENERAL_OBJ = {
  ...GENERAL,
  children: GENERAL_CHILDREN.filter((child) => child.active == true),
};
export const NAVBAR_ITEMS = [
  DASHBOARD_OBJ,
  DAILY_ROUTINE_OBJ,
  ENQUIRY_OBJ,
  IMMIGRATION_OBJ,
  BRANCH_OBJ,
  ACCOUNT_OBJ,
  GENERAL_OBJ,
];

export const GET_BREADCRUMB_ROUTES = (match = "", isParent = false) => {
  if (match == "" || match == " " || match == undefined || match == null)
    return [];

  const filterArray = isParent
    ? [ENQUIRY, DAILY_ROUTINE_OBJ, IMMIGRATION, BRANCH, ACCOUNT, GENERAL]
    : [
        ...ENQUIRY_CHILDREN,
        ...IMMIGRATION_CHILDREN,
        ...BRANCH_CHILDREN,
        ...ACCOUNT_CHILDREN,
        ...GENERAL_CHILDREN,
        ...USER_CHILDREN,
        ...ROLE_CHILDREN,
      ];

  const filteredItem = filterArray.find((item) => item.label === match);
  if (filteredItem == undefined) return [];

  let routes = [{ name: filteredItem.label }];
  const CREATE_ROUTE = (item) => {
    routes.push({
      name: item.label,
      path: item.path,
      isRouteable: item.isRouteable != undefined ? false : true,
    });
    if (item.parent != "") {
      CREATE_ROUTE(item.parent);
    }
  };
  CREATE_ROUTE(filteredItem.parent);

  return routes.reverse();
};
