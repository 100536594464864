import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  CircularProgress,
  Container,
  FormLabel,
  Heading,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import React, { useState } from "react";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES } from "../../core/utils/constants";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import TextInput from "../../theme/components/input/text-input";
import { colors } from "../../theme/styles/colors";
import { useLocation, useNavigate } from "react-router-dom";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import { useEffect } from "react";
import { GRP_MENU_WITH_CHILD, getById } from "../../core/utils/common-function";
import SwitchInput from "../../theme/components/input/switch-input";
import api from "../../core/api";
const ViewRole = () => {
  const param = useLocation();
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [roleId, setRoleId] = useState(param.state.id);
  const [isLoading, setIsLoading] = useState(false);
  const [rolemenus, setRolemenus] = useState([]);
  const [initialState, setInitialState] = useState({
    name: "",
    isActive: false,
    permissions: [],
  });

  const [userPermissions, setUserPermission] = useState([]);
  const { STRING_VALIDATOR } = SCHEMA_VALIDATOR;
  const validationSchema = yup.object({
    roleName: STRING_VALIDATOR,
  });

  const getSingleRecord = async (allpermissions) => {
    try {
      if (roleId) {
        const record = (await getById("Role", roleId)).data;
        if (record.permissions && record.permissions.length == 0) {
          setInitialState({
            ...initialState,
            ...record,
            permissions: GRP_MENU_WITH_CHILD(allpermissions),
          });
          return;
        }

        const moduleId = record.permissions.map((item) => item.moduleId);
        let finalArr = [];
        const uArray = allpermissions.reduce((previous, current, index) => {
          if (moduleId.includes(current.moduleId)) {
            const moduleIndex = moduleId.findIndex(
              (item) => item === current.moduleId
            );
            finalArr[moduleIndex] = record.permissions[moduleIndex];
            return;
          }
          finalArr[index] = current;
        }, []);

        setInitialState({
          ...initialState,
          ...record,
          permissions: GRP_MENU_WITH_CHILD(finalArr),
        });
      }
    } catch (error) {}
  };

  const getAllRecords = () => {
    api
      .post("/Role/getAllPermissionModule")
      .then((res) => {
        getSingleRecord(res.data.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    document.title = "View Role | Digital Filing";
    getAllRecords();
  }, []);

  const activeCard = {
    borderRadius: "10px",
    fontWeight: "600",
    fontSize: "17px",
    overflow: "hidden",
    columns: { base: "1", sm: "1", md: "2", lg: "2" },
    backgroundColor: "rgb(255 255 255)",
    // border: "1px solid #e9e9e9",
    paddingBottom: "0px",
    marginBottom: "30px",
    padding: "10px",
    boxShadow: "0px 0px 30px 0px #d5d5d552",
  };

  return (
    <Container maxW="container.xll" padding={"13px "}>
      <Box>
        <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.VIEW_ROLE)} />
      </Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialState}
        enableReinitialize={true}
        onSubmit={(values) => {
          let finArr = [];
          values.permissions.map((parent) =>
            parent.map((item) => {
              finArr.push(item);
            })
          );
          values.permissions = finArr;
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate={true} onSubmit={(e) => {}}>
            <Box display={"flex"} justifyContent="space-between">
              <Box>
                <Heading variant={"h1"} marginBottom={"13px"}>
                  View Role
                </Heading>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                marginBottom={"13px"}
              >
                <Button
                  variant={"warning"}
                  onClick={() => {
                    navigate("/general/role");
                  }}
                >
                  Back
                </Button>
              </Box>
            </Box>
            <Box
              display={"flex"}
              gap={"13px"}
              borderRadius={"10px"}
              flexDirection={"column"}
            >
              <Box
                padding={"13px"}
                borderRadius={"10px"}
                border={"1px solid #e6e6e6"}
              >
                <SimpleGrid
                  columns={{ base: "1", lg: "1", md: "3", lg: "3" }}
                  spacingX="30px"
                  spacingY="0px"
                >
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="roleName"
                    value={values.name}
                    label={{
                      text: "Role Name",
                      colors: errors.roleName ? "red !important" : "",
                      variant: errors.roleName ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.roleName ? "errored" : "flushed"}
                    isReadOnly={true}
                  />
                  <SwitchInput
                    placeholder={""}
                    isRequired={false}
                    isReadOnly={true}
                    value={values.isActive}
                    name="isActive"
                    variant={errors.isActive ? "errored" : "flushed"}
                    label={{
                      text: "Active",
                      colors: errors.isActive ? "red !important" : "",
                      variant: errors.isActive ? "errored" : "",
                      fontSize: "12px",
                    }}
                    formControl={{ id: "isActive" }}
                    onChange={handleChange}
                  />
                </SimpleGrid>

                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"600"}
                  marginBottom={"5px"}
                >
                  Menu Permissions
                </Box>
                <Box>
                  <Stack
                    spacing={5}
                    direction="row"
                    padding={"0px 0px 25px 3px"}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"5px"}
                  >
                    <FormLabel
                      fontSize={"14px"}
                      fontWeight={"500"}
                      marginLeft={"7px"}
                      color={colors.primaryColor}
                      marginTop={"10px"}
                    ></FormLabel>
                    <Checkbox
                      __css={{
                        "& .chakra-checkbox__control": {
                          background: "#e2e8f0",
                        },
                      }}
                      iconSize="30px"
                      marginLeft={"7px"}
                      size={"lg"}
                      display={"flex"}
                      colorScheme="yellow"
                      isDisabled={true}
                    >
                      <Text fontSize={14} color={"#646262"}>
                        Select All
                      </Text>
                    </Checkbox>
                  </Stack>
                  <SimpleGrid
                    columns={{ base: "1", lg: "1", md: "2", lg: "2" }}
                    spacingX="30px"
                    spacingY="0px"
                  >
                    {values?.permissions.map((childrens, index) => {
                      return (
                        <Box {...activeCard} key={index}>
                          <Box>
                            {childrens.map((item, childIndex) => {
                              return (
                                <Box
                                  w="100%"
                                  justifyContent="space-between"
                                  display="flex"
                                  padding={2}
                                  key={childIndex}
                                >
                                  <FormLabel
                                    fontSize={"13px"}
                                    fontWeight={"400"}
                                    color={"black"}
                                  >
                                    {item.name}
                                  </FormLabel>
                                  <Box
                                    w={"60%"}
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Checkbox
                                      __css={{
                                        "& .chakra-checkbox__control": {
                                          background: "#e2e8f0",
                                        },
                                      }}
                                      display={"flex"}
                                      size="lg"
                                      colorScheme="yellow"
                                      isDisabled={false}
                                      isChecked={item?.hasView}
                                    >
                                      <Text fontSize={13} color={"#646262"}>
                                        View
                                      </Text>
                                    </Checkbox>
                                    <Checkbox
                                      __css={{
                                        "& .chakra-checkbox__control": {
                                          background: "#e2e8f0",
                                        },
                                      }}
                                      isDisabled={false}
                                      display={"flex"}
                                      colorScheme="yellow"
                                      size="lg"
                                      isChecked={item.hasCreate}
                                    >
                                      <Text fontSize={13} color={"#646262"}>
                                        Create
                                      </Text>
                                    </Checkbox>
                                    <Checkbox
                                      __css={{
                                        "& .chakra-checkbox__control": {
                                          background: "#e2e8f0",
                                        },
                                      }}
                                      display={"flex"}
                                      size="lg"
                                      colorScheme="yellow"
                                      isDisabled={false}
                                      isChecked={item.hasEdit}
                                    >
                                      <Text fontSize={13} color={"#646262"}>
                                        Edit
                                      </Text>
                                    </Checkbox>
                                    <Checkbox
                                      __css={{
                                        "& .chakra-checkbox__control": {
                                          background: "#e2e8f0",
                                        },
                                      }}
                                      display={"flex"}
                                      size="lg"
                                      colorScheme="yellow"
                                      isDisabled={false}
                                      isChecked={item.hasDelete}
                                    >
                                      <Text fontSize={13} color={"#646262"}>
                                        Delete
                                      </Text>
                                    </Checkbox>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      );
                    })}
                  </SimpleGrid>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ViewRole;
