export const colors = {
    primaryColor: "#F0AC00",
    background: "#fff",
    borderColor: "  #dad7d7",
    transparent: "transparent",
    black: "#000000",
    color:"#FFFFFF",
    white: "#FFFFFF",
    darkTheme: "#1a202c",
    textColor:"#818181",
    
    alpha: {
      alpha50: "RGBA(0, 0, 0, 0.04)",
      alpha100: "RGBA(0, 0, 0, 0.06)",
      alpha200: "RGBA(0, 0, 0, 0.08)",
      alpha300: "RGBA(0, 0, 0, 0.16)",
      alpha400: "RGBA(0, 0, 0, 0.24)",
      alpha500: "RGBA(0, 0, 0, 0.36)",
      alpha600: "RGBA(0, 0, 0, 0.48)",
      alpha700: "RGBA(0, 0, 0, 0.64)",
      alpha800: "RGBA(0, 0, 0, 0.8)",
      alpha900: "RGBA(0, 0, 0, 0.92)",
    },
    badge:{
      red:"#dd0000",
      redBg:"#dd00001f",
      green:"#00a559",
      greenBg:"#00a52524",
      violet:"#c71585",
      violetBg:"#c7158526",
      yellow:"#ffc109f7",
      yellowBg:"#ffbf0026",
      blueBg:"#6087f63b",
      blue:"#6087f6",
      lightPink:"#185589",
      lightPinkBg:"#becaf5",
      amber:"#ffbf00",
      lilac:"#c8a2c8",
      neonPink:"#f433ff",
      midnightBlue:"#01619e",
      indigo:"#4b0082",
      purpleBlue:"#7B68EE",
      purpleBlueBg:"#7b68ee69",
      lightpurple:"#589182",
      lightpurpleBg:"#2fcfd15c",
      skyBlue:"#f7fefe",
      skyBlueBg:"#09cfdfe6",
      orange:"#a35f49de",
      orangeBg:"#ff8f303d",
      darkpurple:"#7d3df7",
      darkpurpleBg:"#2a00d124",
      sky:"rgb(9 164 255 / 97%)",
      skyBg:"rgb(0 237 215 / 15%)",
      lightBlue:"#2196f3",
      lightBlueBg:"#2196f338",
      lost:"#151928",
      lostBg:"#c4dbd2",
    },
    gray: {
      gray:"#BEBEBE",
      gray50: "#F7FAFC",
      gray100: "#EDF2F7",
      gray200: "#E2E8F0",
      gray300: "#ebebeb",
      gray400: "#A0AEC0",
      gray500: "#718096",
      gray600: "#718096",
      gray700: "#2D3748",
      gray800: "#1A202C",
      gray900: "#171923",
    },
    red: {
      red50: "#FFF5F5",
      red100: "#FED7D7",
      red200: "#FEB2B2",
      red300: "#FC8181",
      red400: "#F56565",
      red500: "#E53E3E",
      red600: "#C53030",
      red700: "#9B2C2C",
      red800: "#822727",
      red900: "#63171B",
    },
    orange: {
      orange50: "#FFFAF0",
      orange100: "#FEEBC8",
      orange200: "#FBD38D",
      orange300: "#F6AD55",
      orange400: "#ED8936",
      orange500: "#DD6B20",
      orange600: "#C05621",
      orange700: "#9C4221",
      orange800: "#7B341E",
      orange900: "#652B19",
    },
    yellow: {
      yellow50: "#FFFFF0",
      yellow100: "#FEFCBF",
      yellow200: "#FAF089",
      yellow300: "#F6E05E",
      yellow400: "#ECC94B",
      yellow500: "#D69E2E",
      yellow600: "#B7791F",
      yellow700: "#975A16",
      yellow800: "#744210",
      yellow900: "#5F370E",
    },
    green: {
      green50: "#F0FFF4",
      green100: "#C6F6D5",
      green200: "#9AE6B4",
      green300: "#68D391",
      green400: "#48BB78",
      green500: "#38A169",
      green600: "#2F855A",
      green700: "#276749",
      green800: "#22543D",
      green900: "#1C4532",
    },
    teal: {
      teal50: "#E6FFFA",
      teal100: "#B2F5EA",
      teal200: "#81E6D9",
      teal300: "#4FD1C5",
      teal400: "#38B2AC",
      teal500: "#319795",
      teal600: "#2C7A7B",
      teal700: "#285E61",
      teal800: "#234E52",
      teal900: "#1D4044",
    },
    blue: {
      blue50: "#EBF8FF",
      blue100: "#BEE3F8",
      blue200: "#90CDF4",
      blue300: "#63B3ED",
      blue400: "#4299E1",
      blue500: "#3182CE",
      blue600: "#2B6CB0",
      blue700: "#2C5282",
      blue800: "#2A4365",
      blue900: "#1A365D",
    },
    cyan: {
      cyan50: "#EDFDFD",
      cyan100: "#C4F1F9",
      cyan200: "#9DECF9",
      cyan300: "#76E4F7",
      cyan400: "#0BC5EA",
      cyan500: "#00B5D8",
      cyan600: "#00A3C4",
      cyan700: "#0987A0",
      cyan800: "#086F83",
      cyan900: "#065666",
    },
    purple: {
      purple50: "#FAF5FF",
      purple100: "#E9D8FD",
      purple200: "#D6BCFA",
      purple300: "#B794F4",
      purple400: "#9F7AEA",
      purple500: "#805AD5",
      purple600: "#6B46C1",
      purple700: "#553C9A",
      purple800: "#44337A",
      purple900: "#6447be",
    },
    pink: {
      pink50: "#FFF5F7",
      pink100: "#FED7E2",
      pink200: "#FBB6CE",
      pink300: "#F687B3",
      pink400: "#ED64A6",
      pink500: "#D53F8C",
      pink600: "#B83280",
      pink700: "#97266D",
      pink800: "#702459",
      pink900: "#521B41",
    },
  };


  
  
  export const spacing = {
    space: {
      px: "1px",
      0.5: "0.125rem",
      1: "0.25rem",
      1.5: "0.375rem",
      2: "0.5rem",
      2.5: "0.625rem",
      3: "0.75rem",
      3.5: "0.875rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      12: "3rem",
      14: "3.5rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      28: "7rem",
      32: "8rem",
      36: "9rem",
      40: "10rem",
      44: "11rem",
      48: "12rem",
      52: "13rem",
      56: "14rem",
      60: "15rem",
      64: "16rem",
      72: "18rem",
      80: "20rem",
      96: "24rem",
    },
  };
  
  export const divBorder = {
    border: "1px",
    borderRadius: 6,
    borderColor: colors.borderColor,
  };
  
  export const divBottomBorder = {
    borderBottom: "1px",
    borderColor: colors.borderColor,
  };
  
  export const divRightBorder = {
    borderRight: "1px",
    borderColor: colors.borderColor,
  };
  
  export const divLeftBorder = {
    borderLeft: "1px",
    borderColor: colors.borderColor,
  };
  

  