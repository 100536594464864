import { useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BiExport } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { useState } from "react";
import { EXPORT_TO_EXCEL } from "../../../core/utils/common-function";
import { colors } from "../../styles/colors";
import { ENQUIRY_STATUS } from "../../../core/utils/constants";
import GridSwitchDropdown from "./grid-switch-dropdown";

const Grid = ({
  name = "excel",
  columns = [],
  aColumns = [],
  data = [],
  permission,
  hasAction = true,
  hasDelete = false,
  hasEdit = true,
  hasExport = false,
  hasColumnDrop = true,
  setColumns = () => void 0,
  setRecordId = () => void 0,
  viewRoute = "",
  editRoute = "",
  isPopupComponent = false,
  otherActions = [],
  isPopupNavComponent = false,
  modeTypes = [],
}) => {
  const Navigate = useNavigate();
  const [gColumns] = useState(columns.filter((x) => x.Active && x.Visibility));
  const sColumns = [
    ...columns
      .filter((x) => x.Visibility)
      .map((item) => ({
        Name: item.Name,
        Alias: item.Alias,
        Active: item.Active,
      })),
  ];

  const [otherActionMenus] = useState(
    otherActions.filter((x) => x.Active && x.Visibility)
  );
  const setColumnWidth = (name) => {
    switch (name) {
      case "Status" || "Action": {
        return "100px";
      }
      default: {
        return "";
      }
    }
  };
  const onClear = () => {
    const hasInActive = gColumns.findIndex((x) => !x.Active);
    if (hasInActive > -1) {
      const cols = columns.map((x) => {
        let col = gColumns.find((f) => f.Name == x.Name);
        x.Active = col == undefined ? x.Active : !col.Active ? true : x.Active;
        return x;
      });
      setColumns(cols);
    }
  };
  const onApply = (e) => {
    if (e.length > 0) {
      const cols = columns.map((x) => {
        x.Active = !e.includes(x.Name) ? false : true;
        return x;
      });
      setColumns(cols);
    }
  };

  return (
    <Box border={"1px solid #e6e6e6"} borderRadius={"10px"} padding={"10px"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={"15px"}
      >
        <Box alignItems={"center"} display={{ base: "none", md: "flex" }}>
          {modeTypes.map((mode, index) => (
            <Box display={"flex"} justifyContent="space-between" gap={"10px"}>
              <Box
                width={"15px"}
                height={"15px"}
                background={mode.textColour}
                borderRadius={"50%"}
                border={`2px solid ${mode.textColour}`}
              ></Box>
              <FormLabel fontSize={"12px"} textAlign={"center"}>
                {mode.text}
              </FormLabel>
            </Box>
          ))}
        </Box>
        <Box display={"flex"} justifyContent={"end"} gap={"10px"}>
          {hasColumnDrop && (
            <SimpleGrid
              columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
              spacingX="40px"
              spacingY="20px"
              minWidth={"100px"}
            >
              <GridSwitchDropdown
                label="Fields"
                options={sColumns}
                onClear={() => onClear()}
                onApply={(e) => onApply(e)}
                fontSize={"11px"}
              />
            </SimpleGrid>
          )}

          {hasExport && (
            <Button
              fontSize={{
                base: "12px",
                sm: "13px",
                md: "14px",
                lg: "14px",
              }}
              variant={"secondary"}
              marginLeft={"10px"}
              onClick={() => EXPORT_TO_EXCEL(name, aColumns, data)}
            >
              <BiExport fontSize={"20px"} />
              <Text marginLeft={"5px"}>Export</Text>
            </Button>
          )}
        </Box>
      </Box>

      <TableContainer
        fontSize={"15px"}
        borderRadius={"10px"}
        overflowY="auto"
        minH={"330px"}
      >
        <Table variant="striped">
          <Thead>
            <Tr>
              {hasAction && (
                <Th
                  maxWidth="100px"
                  w={"100px"}
                  padding={"10px"}
                  borderBottom={`2px solid ${colors.primaryColor}`}
                >
                  Action
                </Th>
              )}
              {columns.map(
                (column, index) =>
                  column.Active && (
                    <Th
                      maxWidth={setColumnWidth(column.Name)}
                      w={setColumnWidth(column.Name)}
                      padding={"10px"}
                      key={index}
                      borderBottom={`2px solid ${colors.primaryColor}`}
                    >
                      {column.Alias}
                    </Th>
                  )
              )}
            </Tr>
          </Thead>

          <Tbody>
            {data.length > 0 ? (
              data.map((val, index) => {
                return (
                  <Tr key={index}>
                    {columns.length > 0
                      ? columns.map((column) => {
                          {
                            if (column.Visibility || column.ForAction) {
                              /* HIDE/SHOW: COLUMN IN THE TABLE USING "ACTIVE" PROPERTY */
                              if (column.ForAction) {
                                /* Td: FOR ACTION */
                                return (
                                  <Td padding={"10px"} fontSize={"13px"}>
                                    <Box
                                      display={"flex"}
                                      gap={"5px"}
                                      alignItems={"center"}
                                    >
                                      {val.Mode != undefined && (
                                        /* Td: FOR FILE TYPE - CIRCULAR PILL */
                                        <Box
                                          width={"15px"}
                                          height={"15px"}
                                          borderRadius={"50%"}
                                          backgroundColor={val.Mode.textColour}
                                          border={val.Mode.textColour}
                                        ></Box>
                                      )}
                                      <Menu isLazy>
                                        <MenuButton>
                                          <BsThreeDotsVertical />
                                        </MenuButton>
                                        <MenuList minWidth={"107px"}>
                                          {permission.hasView && (
                                            <MenuItem
                                              display={"flex"}
                                              gap={"10px"}
                                              onClick={() => {
                                                if (
                                                  isPopupComponent &&
                                                  !isPopupNavComponent
                                                ) {
                                                  setRecordId(val.Id, {
                                                    isView: true,
                                                    isEdit: false,
                                                    isDelete: false,
                                                  });
                                                  return;
                                                }
                                                Navigate(viewRoute, {
                                                  state: {
                                                    id: val.Id,
                                                    enquiryID: val?.enquiryID,
                                                    isView: true,
                                                  },
                                                });
                                              }}
                                            >
                                              <AiOutlineEye color={"#1AAA00"} />
                                              View
                                            </MenuItem>
                                          )}
                                          {permission.hasEdit && hasEdit && (
                                            <MenuItem
                                              display={"flex"}
                                              gap={"10px"}
                                              onClick={() => {
                                                if (
                                                  isPopupComponent &&
                                                  !isPopupNavComponent
                                                ) {
                                                  setRecordId(val.Id, {
                                                    isView: false,
                                                    isEdit: true,
                                                    isDelete: false,
                                                  });
                                                  return;
                                                }
                                                Navigate(editRoute, {
                                                  state: {
                                                    id: val.Id,
                                                    isEdit: true,
                                                    enquiryID: val?.enquiryID,
                                                  },
                                                });
                                              }}
                                            >
                                              <FiEdit />
                                              Edit
                                            </MenuItem>
                                          )}
                                          {permission.hasDelete &&
                                            hasDelete && (
                                              <MenuItem
                                                onClick={() => {
                                                  setRecordId(val.Id, {
                                                    isView: false,
                                                    isEdit: false,
                                                    isDelete: true,
                                                  });
                                                  return;
                                                }}
                                                display={"flex"}
                                                gap={"10px"}
                                              >
                                                <AiFillDelete
                                                  color={"#E61515"}
                                                />
                                                Delete
                                              </MenuItem>
                                            )}
                                          {permission.hasView &&
                                            otherActionMenus.length > 0 &&
                                            otherActionMenus.map(
                                              (action) =>
                                                [
                                                  ENQUIRY_STATUS.CLOSED,
                                                  ENQUIRY_STATUS.CONVERTED_TO_FILE,
                                                  ENQUIRY_STATUS.ENROLLED,
                                                  ENQUIRY_STATUS.LOST,
                                                  ENQUIRY_STATUS.NOT_ELIGIBLE,
                                                  ENQUIRY_STATUS.NOT_INTERESTED,
                                                ].filter(
                                                  (item) =>
                                                    item == val?.enquiryStatusId
                                                ).length == 0 && (
                                                  <MenuItem
                                                    onClick={() => {
                                                      if (
                                                        action.NavigateRoute ==
                                                        ""
                                                      ) {
                                                        setRecordId(val.Id, {
                                                          isView: false,
                                                          isEdit: false,
                                                          isDelete: false,
                                                          [action["Key"]]: true,
                                                          enquiryStatusId:
                                                            val.enquiryStatusId,
                                                          caseStatusId:
                                                            val.caseStatusId,
                                                        });
                                                        return;
                                                      }

                                                      Navigate(
                                                        action.NavigateRoute,
                                                        {
                                                          state: {
                                                            id: val.Id,
                                                            enquiryID:
                                                              val?.enquiryId,
                                                            isEdit: false,
                                                            isView: false,
                                                            isEnquiry: true,
                                                          },
                                                        }
                                                      );
                                                    }}
                                                    display={"flex"}
                                                    gap={"10px"}
                                                  >
                                                    {action.Icon}
                                                    {action.Name}
                                                  </MenuItem>
                                                )
                                            )}
                                        </MenuList>
                                      </Menu>
                                    </Box>
                                  </Td>
                                );
                              } else if (column.Active && column.ForStatus) {
                                /* Td: FOR STATUS */
                                return (
                                  <Td padding={"10px"} fontSize={"12px"}>
                                    <Badge
                                      colorScheme={val.Status ? "green" : "red"}
                                      fontWeight={"500"}
                                      style={{ display: "inline-block" }}
                                      padding={"3px 10px"}
                                      borderRadius={"5px"}
                                    >
                                      {val.Status ? "Active" : "Inactive"}
                                    </Badge>
                                  </Td>
                                );
                              } else if (
                                column.Active &&
                                column.ForCaseStatus
                              ) {
                                /* Td: FOR IMMIGRATION FILE STATUS */
                                return (
                                  <Td padding={"10px"} fontSize={"12px"}>
                                    <Box
                                      fontWeight={"500"}
                                      padding={"3px 10px"}
                                      borderRadius={"5px"}
                                      fontSize={"12px"}
                                      textAlign={"center"}
                                      backgroundColor={val.Status.bgColour}
                                      color={val.Status.textColour}
                                      style={{ display: "inline-block" }}
                                    >
                                      {val.Status.text}
                                    </Box>
                                  </Td>
                                );
                              } else

                              /* Td: FOR OTHER(S) */
                                return (
                                  column.Active && (
                                    <Td padding={"10px"} fontSize={"13px"}>
                                      {val[column.Name]}
                                    </Td>
                                  )
                                );
                            }
                          }
                        })
                      : ""}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td rowSpan={"10"} colSpan={"28"} textAlign={"center"}>
                  No record found
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Grid;
