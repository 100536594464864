import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
} from "@chakra-ui/react";
import SwitchInput from "../input/switch-input";

const GridSwitchDropdown = (props) => {
  const { label, options, buttonProps, onClear, onApply } = props;
  const [selectedOptions, setSelectedOptions] = useState(
    options.filter((x) => x.Active).map((x) => x.Name)
  );

  return (
    <Menu closeOnSelect={false} variant={"roundLeft"}>
      {({ onClose }) => (
        <>
          <MenuButton type="button" {...buttonProps}>
            {`${label}`}
          </MenuButton>
          <MenuList minWidth={"100px"} maxHeight={"200px"} overflowX={"auto"}>
            {options.map((option, index) => {
              return (
                <MenuItem gap={"10px"} fontSize={"13px"}>
                  <SwitchInput
                    label={{ display: "none" }}
                    isDisabled={index <= 1}
                    defaultChecked={option.Active}
                    name={option.Name}
                    box={{ marginBottom: 0 }}
                    onChange={(e) => {
                      const index = selectedOptions.indexOf(e.target.name);
                      if (e.target.checked) {
                        setSelectedOptions([...selectedOptions, e.target.name]);
                        onApply([...selectedOptions, e.target.name]);
                      } else {
                        selectedOptions.splice(index, 1);
                        setSelectedOptions([...selectedOptions]);
                        onApply([...selectedOptions]);
                      }
                    }}
                  />
                  {option.Alias}
                </MenuItem>
              );
            })}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
export default GridSwitchDropdown;
