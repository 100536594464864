import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Image,
  Button,
  Select,
  CircularProgress,
  Text,
  CloseButton,
  Avatar,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import React, { useEffect } from "react";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";

import { Form, Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { colors } from "../../theme/styles/colors";
import {
  ERROR_CODES,
  MENU_NAMES,
  REFRESH_TOKEN_KEY,
  SIGN_IN,
  USER_AUTH_TOKEN_KEY,
} from "../../core/utils/constants";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import SelectInput from "../../theme/components/input/select-input";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import {
  convertDateFormat,
  getAll,
  getById,
  PARSE_JWT,
} from "../../core/utils/common-function";
import api from "../../core/api";
import { SuccessAlert } from "../../core/utils/common-sweet-alert";
import { useDispatch, useSelector } from "react-redux";
import SwitchInput from "../../theme/components/input/switch-input";
import { userSignIn, userSignOut } from "../../core/store/actions";

const Profile = () => {
  const selector = useSelector((_) => _);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    BOOLEAN_VALIDATOR,
    DATE_VALIDATOR,
    EMAIL_VALIDATOR,
    PHONE_NUMBER_VALIDATOR,
    STRING_VALIDATOR,
    ALPHA_NUMERIC_VALIDATOR,
    PASSWORD_VALIDATOR,
    GUID_VALIDATOR,
    FILE_VALIDATOR,
    CONFIRM_PASSWORD_VALIDATOR,
  } = SCHEMA_VALIDATOR;
  const [show, setShow] = React.useState(false);
  const [district, setDistrict] = useState([]);
  const [state, setState] = useState([]);
  const isEdit = false;
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [imagePathAvtar, setImagePathAvtar] = useState(
    selector.auth.login.ImagePath
  );
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const [initialState, setInitialState] = useState({
    name: "",
    emailId: "",
    phoneNumber: "",
    dob: "",
    stateId: null,
    districtId: null,
    address: "",
    file: "",
    // isActive: false,
    // isAdmin: false,
  });
  const [imagePath, setImagePath] = useState(selector.auth.login);
  const [securityState, setSecurityState] = useState({
    oldPassword: "",
    password: "",
    currentPassword: "",
  });
  const generalSchema = yup.object({
    name: STRING_VALIDATOR,
    emailId: EMAIL_VALIDATOR,
    phoneNumber: PHONE_NUMBER_VALIDATOR,
    dob: DATE_VALIDATOR,
    stateId: GUID_VALIDATOR,
    districtId: GUID_VALIDATOR,
    address: ALPHA_NUMERIC_VALIDATOR,
    file: FILE_VALIDATOR,
    // isActive: BOOLEAN_VALIDATOR,
    // isAdmin: BOOLEAN_VALIDATOR,
    // userName: STRING_VALIDATOR,
    // password: PASSWORD_VALIDATOR,
  });
  const securitySchema = yup.object({
    oldPassword: PASSWORD_VALIDATOR,
    password: PASSWORD_VALIDATOR,
    confirmPassword: CONFIRM_PASSWORD_VALIDATOR,
  });

  useEffect(() => {
    document.title = "Profile | Digital Filing";
    getAllPromises();
    getSingleUser();
  }, [payload]);

  useEffect(() => {
    if (imagePathAvtar != selector.auth.login.ImagePath)
      setImagePathAvtar(selector.auth.login.ImagePath);
  }, [selector.auth.login.ImagePath]);

  const getSingleUser = async () => {
    const singleUser = (await getById("User", selector.auth.login.UserId)).data;
    setInitialState({
      name: singleUser.name,
      dob: convertDateFormat(singleUser.dob, "yyyy-mm-dd"),
      emailId: singleUser.emailId,
      phoneNumber: singleUser.phoneNumber,
      stateId: singleUser.stateId,
      districtId: singleUser.districtId,
      address: singleUser.address,
      isActive: singleUser.isActive,
      isAdmin: singleUser.isAdmin,
      file: singleUser.file,
    });
  };

  const getAllPromises = () => {
    const district = getAll("District", payload);
    const state = getAll("State", payload);

    Promise.all([district, state])
      .then((res) => {
        const [dist, stat] = res;
        const districts = dist.data.data.map((dist) => ({
          text: dist.name,
          value: dist.districtId,
        }));
        const states = stat.data.data.map((sta) => ({
          text: sta.name,
          value: sta.stateId,
        }));
        setDistrict(districts);
        setState(states);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <Container maxW="container.xll" marginTop={"20px"}>
        <Box>
          <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.PROFILE)} />
        </Box>
        <Box display={"flex"} justifyContent="space-between">
          <Box>
            <Heading variant={"h1"} marginBottom={"20px"}>
              Profile
            </Heading>
          </Box>
        </Box>
        <Tabs variant={"colorful"} size="md">
          <TabList borderBottom={"1px solid #e9e9e9"} marginBottom={"20px"}>
            <Tab fontSize={"14px"}>General</Tab>
            {selector.auth.login.RoleName.includes("Admin") && (
              <Tab fontSize={"14px"}>Security</Tab>
            )}
          </TabList>

          <TabPanels>
            <TabPanel
              padding={"20px"}
              borderRadius={"10px"}
              border={"1px solid #e6e6e6"}
            >
              <Box>
                <Formik
                  validationSchema={generalSchema}
                  enableReinitialize={true}
                  initialValues={initialState}
                  onSubmit={async (values) => {
                    const finalPayload = {
                      UserId: selector.auth.login.UserId,
                      BranchId: selector.branch.userBranchId,
                      Name: values.name,
                      EmailId: values.emailId,
                      PhoneNumber: values.phoneNumber,
                      Dob: values.dob,
                      StateId: values.stateId,
                      DistrictId: values.districtId,
                      Address: values.address,
                      IsActive: true,
                      IsAdmin: selector.auth.login.RoleId === 1,
                      File: values.file,
                    };
                    // if(finalPayload.file){
                    //   setImagePath({...imagePath, imagePath:values.file.name});
                    //   dispatch(SIGN_IN(imagePath));
                    // return
                    // }
                    // finalPayload.file =
                    //   values.file == undefined ? null : values.file;
                    if (finalPayload.File === undefined)
                      finalPayload.File = null;
                    else finalPayload.File = values.file;

                    let response = await api.put("User/replace", finalPayload, {
                      headers: { "Content-Type": "multipart/form-data" },
                    });
                    if (response.status === ERROR_CODES.ACCEPTED) {
                      SuccessAlert("User Updated Successfully");
                      localStorage.setItem(
                        USER_AUTH_TOKEN_KEY,
                        JSON.stringify(response.data.data.accessToken)
                      );
                      localStorage.setItem(
                        REFRESH_TOKEN_KEY,
                        JSON.stringify(response.data.data.refreshToken)
                      );
                      dispatch(
                        userSignIn(PARSE_JWT(response.data.data.accessToken))
                      );
                    }
                    return;
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form
                      noValidate={true}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <Box
                        spacingX="30px"
                        spacingY="0px"
                        display={"flex"}
                        w={"100%"}
                      >
                        <Box maxW={"210px"} width={"100%"}>
                          <Box
                            position={"relative"}
                            className="uploadImagesBox"
                          >
                            <TextInput
                              type="file"
                              white={"80px"}
                              isRequired={false}
                              name="file"
                              label={{
                                text: "",
                              }}
                              accept={".png,.jpg,.jpeg"}
                              onChange={(event) => {
                                let file = null;
                                if (
                                  event.target.files &&
                                  event.target.files[0]
                                ) {
                                  file = event.target.files[0];
                                  if (file.size > 1024 * 1024 * 2)
                                    alert(
                                      "Please upload a file smaller than 2 MB"
                                    );
                                  else {
                                    setImage(URL.createObjectURL(file));
                                    setFieldValue("file", file);
                                    setInitialState({
                                      ...initialState,
                                      ...values,
                                    });
                                  }
                                }
                              }}
                              placeholder={""}
                              isReadOnly={isLoading}
                              position={"absolute"}
                            />
                            <CloseButton
                              className="closeUploadedImage"
                              onClick={() => setImage("")}
                            />
                            {/* <Image className="uploadedImage" src={image != null ? image : 'https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png'} /> */}
                            <Avatar
                              className="uploadedImage"
                              src={
                                image == ""
                                  ? `https://api.h-simmigration.com/Uploads/${imagePathAvtar}`
                                  : image
                              }
                            ></Avatar>
                          </Box>
                        </Box>

                        <SimpleGrid
                          columns={{ base: "1", sm: "1", md: "2", lg: "4" }}
                          spacingX="30px"
                          spacingY="0px"
                          w={"100%"}
                        >
                          <TextInput
                            type="text"
                            isRequired={true}
                            name="name"
                            value={values.name}
                            isDisabled={
                              selector.auth.login.RoleId === "1" ? false : true
                            }
                            label={{
                              text: "Name",
                              colors: errors.name ? "red !important" : "",
                              variant: errors.name ? "errored" : "",
                            }}
                            onChange={(e) => handleChange(e)}
                            placeholder={""}
                            variant={errors.name ? "errored" : "flushed"}
                            isReadOnly={isLoading}
                          />
                          <TextInput
                            type="text"
                            name="emailId"
                            isRequired={true}
                            value={values.emailId}
                            isDisabled={
                              selector.auth.login.RoleId === "1" ? false : true
                            }
                            label={{
                              text: "Email",
                              colors: errors.emailId ? "red !important" : "",
                              variant: errors.emailId ? "errored" : "",
                            }}
                            onChange={handleChange}
                            placeholder={""}
                            variant={errors.emailId ? "errored" : "flushed"}
                            isReadOnly={isLoading}
                          />
                          <TextInput
                            type="date"
                            name="dob"
                            isRequired={true}
                            placeholder={""}
                            value={values.dob}
                            label={{
                              text: "DOB",
                              colors: errors.dob ? "red !important" : "",
                              variant: errors.dob ? "errored" : "",
                            }}
                            onChange={handleChange}
                            variant={errors.dob ? "errored" : "flushed"}
                            isReadOnly={isLoading}
                          />
                          <TextInput
                            type="text"
                            isRequired={true}
                            name="phoneNumber"
                            value={values.phoneNumber}
                            label={{
                              text: "Phone Number",
                              colors: errors.phoneNumber
                                ? "red !important"
                                : "",
                              variant: errors.phoneNumber ? "errored" : "",
                            }}
                            onChange={(e) => handleChange(e)}
                            placeholder={""}
                            variant={errors.phoneNumber ? "errored" : "flushed"}
                            isReadOnly={isLoading}
                          />
                          <SelectInput
                            formControl={{
                              id: "stateId",
                              variant: "floating",
                            }}
                            isRequired={true}
                            isReadOnly={isLoading}
                            value={values.stateId}
                            label={{
                              text: "State",
                              colors: errors.stateId ? "red !important" : "",
                              variant: errors.stateId ? "errored" : "",
                            }}
                            options={state}
                            onChange={handleChange}
                            variant={errors.stateId ? "errored" : "flushed"}
                          />
                          <SelectInput
                            formControl={{
                              id: "districtId",
                              variant: "floating",
                            }}
                            isRequired={true}
                            isReadOnly={isLoading}
                            value={values.districtId}
                            label={{
                              text: "District",
                              colors: errors.districtId ? "red !important" : "",
                              variant: errors.districtId ? "errored" : "",
                            }}
                            options={district}
                            onChange={handleChange}
                            variant={errors.districtId ? "errored" : "flushed"}
                          />
                          <TextInput
                            type="text"
                            name="address"
                            placeholder={""}
                            isRequired={true}
                            isReadOnly={isLoading}
                            value={values.address}
                            variant={errors.address ? "errored" : "flushed"}
                            label={{
                              text: "Address",
                              colors: errors.address ? "red !important" : "",
                              variant: errors.address ? "errored" : "",
                            }}
                            onChange={(e) => handleChange(e)}
                          />

                          {/* <SwitchInput
                            placeholder={""}
                            isRequired={true}
                            isReadOnly={isLoading}
                            value={values.isActive}
                            name="isActive"
                            variant={errors.isActive ? "errored" : "flushed"}
                            label={{
                              text: "Active",
                              colors: errors.isActive ? "red !important" : "",
                              variant: errors.isActive ? "errored" : "",
                              fontSize: "12px",
                            }}
                            formControl={{ id: "isActive" }}
                            onChange={handleChange}
                          />

                          <SwitchInput
                            formControl={{ id: "isAdmin" }}
                            placeholder={""}
                            isRequired={true}
                            isReadOnly={isLoading}
                            value={values.isAdmin}
                            name="isAdmin"
                            variant={errors.isAdmin ? "errored" : "flushed"}
                            label={{
                              text: "Make Admin",
                              colors: errors.isAdmin ? "red !important" : "",
                              variant: errors.isAdmin ? "errored" : "",
                              fontSize: "12px",
                            }}
                            onChange={handleChange}
                          />

                          <TextInput
                            type="text"
                            name="username"
                            placeholder={""}
                            isRequired={true}
                            value={values.username}
                            variant={errors.username ? "errored" : "flushed"}
                            label={{
                              text: "Username",
                              colors: errors.username ? "red !important" : "",
                              variant: errors.username ? "errored" : "",
                            }}
                            onChange={handleChange}
                          />
                          <TextInput
                            type="text"
                            name="password"
                            placeholder={""}
                            isRequired={true}
                            value={values.password}
                            variant={errors.password ? "errored" : "flushed"}
                            label={{
                              text: "Password",
                              colors: errors.password ? "red !important" : "",
                              variant: errors.password ? "errored" : "",
                            }}
                            onChange={handleChange}
                          /> */}
                        </SimpleGrid>
                      </Box>

                      <Box display={"flex"} justifyContent={"end"}>
                        <Button variant={"primary"} type="submit">
                          {isLoading ? (
                            <CircularProgress
                              isIndeterminate
                              size="24px"
                              color={colors.white}
                            />
                          ) : (
                            "Save Changes"
                          )}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </TabPanel>
            <TabPanel padding={"0px"}>
              <Box>
                <Formik
                  validationSchema={securitySchema}
                  enableReinitialize={true}
                  initialValues={initialState}
                  onSubmit={async (values) => {
                    const finalPayload = {
                      userId: selector.auth.login.UserId,
                      currentPassword: values.oldPassword,
                      newPassword: values.password,
                      confirmPassword: values.confirmPassword,
                    };

                    let response = await api.put(
                      "/User/replace-password",
                      finalPayload
                    );
                    if (response.status === ERROR_CODES.ACCEPTED) {
                      SuccessAlert("User Updated Successfully");
                      // dispatch(userSignOut());
                      navigate(ROUTE_LINKS[MENU_NAMES.LANDING]);
                    }
                    return;
                  }}
                >
                  {({ handleSubmit, handleChange, values, errors }) => (
                    <Form
                      noValidate={true}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <Box
                        w={{ base: "100%", md: "50%", lg: "50%", xl: "50%" }}
                        padding={"20px"}
                        borderRadius={"10px"}
                        border={"1px solid #e6e6e6"}
                      >
                        <TextInput
                          type="password"
                          isRequired={true}
                          name="oldPassword"
                          value={values.oldPassword}
                          label={{
                            text: "Old Password",
                            colors: errors.oldPassword ? "red !important" : "",
                            variant: errors.oldPassword ? "errored" : "",
                          }}
                          onChange={(e) => handleChange(e)}
                          placeholder={""}
                          variant={errors.oldPassword ? "errored" : "flushed"}
                          isReadOnly={isLoading}
                          icon={{
                            visible: true,
                          }}
                        />
                        <TextInput
                          type="password"
                          isRequired={true}
                          name="password"
                          value={values.password}
                          label={{
                            text: "New Password",
                            colors: errors.password ? "red !important" : "",
                            variant: errors.password ? "errored" : "",
                          }}
                          onChange={(e) => handleChange(e)}
                          placeholder={""}
                          variant={errors.password ? "errored" : "flushed"}
                          isReadOnly={isLoading}
                          icon={{
                            visible: true,
                          }}
                        />
                        <TextInput
                          type="password"
                          isRequired={true}
                          name="confirmPassword"
                          value={values.confirmPassword}
                          label={{
                            text: "Confirm Password",
                            colors: errors.confirmPassword
                              ? "red !important"
                              : "",
                            variant: errors.confirmPassword ? "errored" : "",
                          }}
                          onChange={(e) => handleChange(e)}
                          placeholder={""}
                          variant={
                            errors.confirmPassword ? "errored" : "flushed"
                          }
                          isReadOnly={isLoading}
                          icon={{
                            visible: true,
                          }}
                        />
                        <Box display={"flex"} justifyContent={"end"}>
                          <Button
                            variant={"primary"}
                            color={colors.white}
                            size="md"
                            type="submit"
                          >
                            {isLoading ? (
                              <CircularProgress
                                isIndeterminate
                                size="24px"
                                color={colors.white}
                              />
                            ) : (
                              "Update Password"
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </div>
  );
};

export default Profile;
