import { Heading, SimpleGrid, Box, Container } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ArrowDownIcon, ArrowUpIcon, ChevronRightIcon } from "@chakra-ui/icons";
import "react-calendar/dist/Calendar.css";
import { colors } from "../../theme/styles/colors";
import MonthWiseReport from "./chart-reports/month-wise-report";
import Channel from "./chart-reports/channel";
import LineChart from "./chart-reports/line-chart";
import SelectInput from "../../theme/components/input/select-input";
import { useSelector } from "react-redux";
import api from "../../core/api";
import Grid from "../../theme/components/grid/grid";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { CREATE_RESPONSE } from "../../core/utils/common-function";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { MENU_NAMES } from "../../core/utils/constants";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const selector = useSelector((_) => _);
  const activeCard = {
    borderRadius: "10px",
    fontWeight: "600",
    fontSize: "17px",
    overflow: "hidden",
    columns: { base: "1", sm: "1", md: "2", lg: "2" },
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid #e9e9e9",
    paddingBottom: "0px",
  };
  const cardTitle = {
    paddingBottom: "10px",
    fontWeight: "400",
    color: colors.black,
    fontSize: "20px",
  };
  const [dashboardStats, setDashboardStats] = useState({
    activeFiles: 0,
    lastWeekActiveFiles: 0,
    percentageDifference: 0,
    isPositiveDifference: false,
    activeEnquiry: 0,
    convertedEnquiry: 0,
    fileAdmissioned: 0,
    fileLodged: 0,
    pendingFileAdmission: 0,
    pendingFileReady: 0,
    id: null,
    enquiryStats: [],
    fileStats: [],
    visaStats: [],
    logs: [],
  });
  const [enquiryStats, setEnquiryStats] = useState([]);
  const [fileStats, setFileStats] = useState([]);
  const [visaStats, setVisaStats] = useState([]);
  const [logStats, setLogStats] = useState([]);
  const [apiPayload, setAPIPayload] = useState({
    filterBy: 1,
    branchId: selector.branch.userBranchId,
  });

  const onFilterChange = (val) => {
    setAPIPayload({
      ...apiPayload,
      filterBy: val,
    });
  };

  const createEnquiryStats = (array = []) => {
    const year = new Date().getFullYear();
    let enquiries = [],
      enrollments = [],
      filesReady = [];
    for (let index = 1; index <= 12; index++) {
      const iIndex = array.findIndex((x) => x.month == index);
      enquiries.push({
        x: new Date(year, index - 1),
        y: iIndex > -1 ? array[iIndex].totalEnquiry : 0,
      });
      enrollments.push({
        x: new Date(year, index - 1),
        y: iIndex > -1 ? array[iIndex].totalEnrollment : 0,
      });
      filesReady.push({
        x: new Date(year, index - 1),
        y: iIndex > -1 ? array[iIndex].totalFileReady : 0,
      });
    }
    return [enquiries, enrollments, filesReady];
  };

  const createFileStats = (array = []) => {
    const year = new Date().getFullYear();
    let visaGrants = [],
      visaRefuses = [],
      filesOnHold = [];
    for (let index = 1; index <= 12; index++) {
      const iIndex = array.findIndex((x) => x.month == index);
      visaGrants.push({
        x: index,
        y: iIndex > -1 ? array[iIndex].totalVisaGranted : 0,
      });
      visaRefuses.push({
        x: index,
        y: iIndex > -1 ? array[iIndex].totalVisaRefused : 0,
      });
      filesOnHold.push({
        x: index,
        y: iIndex > -1 ? array[iIndex].totalFileOnHold : 0,
      });
    }
    return [visaGrants, visaRefuses, filesOnHold];
  };

  const createVisaStats = (array = []) => {
    let result = [];
    for (let index = 0; index <= array.length - 1; index++) {
      const element = array[index];
      result.push({
        name: element.countryName,
        indexLabel:`Granted-${element.totalVisaGranted} Refused-${element.totalVisaRefused}`,
        x: element.totalVisaGranted,
        y: element.totalVisaRefused,
        color:
          index == 0
            ? colors.purple.purple600
            : index == 1
            ? colors.cyan.cyan600
            : colors.pink.pink600,
      });
    }
    return result;
  };

  const getDashboardKPIs = () => {
    api
      .get(`Dashboard/get/${apiPayload.filterBy}/${apiPayload.branchId}`)
      .then((res) => {
        const enquiryStats = createEnquiryStats(res.data.data.enquiryStats);
        const fileStats = createFileStats(res.data.data.fileStats);
        const visaStats = createVisaStats(res.data.data.visaStats);
        setLogStats(
          CREATE_RESPONSE(GRID_COLUMNS.DASHBOARD_LOGS, res.data.data.logs)
        );
        setEnquiryStats(enquiryStats);
        setFileStats(fileStats);
        setVisaStats(visaStats);
        setDashboardStats(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getDashboardKPIs();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  return (
    <Box width={"100%"}>
      <Container maxW="container.xll" marginTop={"10px"}>
        <Box display="flex" alignItems={"center"} justifyContent={"end"}>
          <SimpleGrid
            columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
            spacingX="40px"
            padding={"0px"}
            paddingBottom={"0px"}
            minW={"100px"}
          >
            <SelectInput
              marginBottom={"0px"}
              formControl={{ id: "filterBy", variant: "floating" }}
              isRequired={false}
              box={{ marginBottom: 0 }}
              height={"48px"}
              isReadOnly={false}
              value={apiPayload.filterBy}
              name="filterBy"
              onChange={(e) => onFilterChange(e.target.value)}
              label={{
                text: "Filter By",
              }}
              options={[
                { text: "Today", value: 1 },
                { text: "Last Week", value: 2 },
                { text: "This Month", value: 3 },
                { text: "Last Month", value: 4 },
                { text: "This Year", value: 5 },
                { text: "Last Year", value: 6 },
              ]}
            />
          </SimpleGrid>
        </Box>

        <Box
          colStart={4}
          border={"1px solid #e9e9e9"}
          padding={"20px"}
          borderRadius={"10px"}
          marginTop={"20px"}
          marginBottom={"20px"}
        >
          <Box
            display={"flex"}
            flexDirection={{
              base: "column",
              md: "column",
              lg: "row",
              lg: "row",
            }}
          >
            <Box w={{ base: "100%", md: "100%", lg: "50%", lg: "50%" }}>
              <Heading alignItems={"center"} {...cardTitle}>
                Enquiry Statistics
              </Heading>
            </Box>
          </Box>
          <Box>
            <LineChart dataPoints={enquiryStats} />
          </Box>
        </Box>
        <SimpleGrid
          columns={{ base: "1", md: "1", lg: "2", lg: "2" }}
          gap={4}
          rowSpan={{ base: "1", md: "1", lg: "2", lg: "2" }}
        >
          <Box colSpan={0} templateColumns="repeat(2, 2fr)">
            <SimpleGrid
              columns={{ base: "1", md: "2", lg: "2", lg: "2" }}
              gap={4}
              rowSpan={{ base: "1", md: "1", lg: "2", lg: "2" }}
            >
              <Box {...activeCard}>
                <Box padding={"20px"} minHeight={"153px"}>
                  <Box {...cardTitle}>Active Files</Box>
                  <Box display={"flex"}>
                    <Box
                      fontSize={"36px"}
                      fontWeight={"500"}
                      lineHeight={"52px"}
                    >
                      {dashboardStats.activeFiles}
                    </Box>
                    <Box
                      borderRadius={"10px"}
                      padding={"10px"}
                      marginLeft={"13px"}
                      fontSize={"12px"}
                      height={"37px"}
                      color={
                        dashboardStats.isPositiveDifference
                          ? "#38A169"
                          : "#ED4F9D"
                      }
                      backgroundColor={
                        dashboardStats.isPositiveDifference
                          ? "#C6F6D5"
                          : "#FDF2F8"
                      }
                    >
                      {dashboardStats.isPositiveDifference ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      )}
                      {dashboardStats.percentageDifference}%
                    </Box>
                  </Box>
                  <Box fontSize={"12px"} marginTop={"0px"}>
                    Last week {dashboardStats.lastWeekActiveFiles}
                  </Box>
                </Box>
              </Box>
              <Box {...activeCard}>
                <Box padding={"20px"} minHeight={"153px"}>
                  <Box {...cardTitle}>Monthly Reports</Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box fontSize={"14px"} fontWeight={"500"}>
                      Enquiries:
                    </Box>
                    <Box fontSize={"14px"} fontWeight={"500"}>
                      Converted:
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      lineHeight={"52px"}
                    >
                      <Box
                        display={"flex"}
                        fontSize={"36px"}
                        fontWeight={"500"}
                        lineHeight={"52px"}
                      >
                        {dashboardStats.activeEnquiry}
                      </Box>
                      <Box
                        display={"flex"}
                        fontSize={"36px"}
                        fontWeight={"500"}
                        lineHeight={"52px"}
                      >
                        {dashboardStats.convertedEnquiry}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box {...activeCard}>
                <Box padding={"20px"} minHeight={"153px"}>
                  <Box {...cardTitle}>Filing Work Pending</Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box fontSize={"14px"} fontWeight={"500"}>
                      Admission:
                    </Box>
                    <Box fontSize={"14px"} fontWeight={"500"}>
                      Lodgement:
                    </Box>
                  </Box>
                  <Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Box
                        display={"flex"}
                        fontSize={"36px"}
                        fontWeight={"500"}
                        lineHeight={"52px"}
                      >
                        {dashboardStats.fileAdmissioned}
                      </Box>
                      <Box
                        display={"flex"}
                        fontSize={"36px"}
                        fontWeight={"500"}
                        lineHeight={"52px"}
                      >
                        {dashboardStats.fileLodged}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box {...activeCard}>
                <Box padding={"20px"} minHeight={"153px"}>
                  <Box {...cardTitle}>Branch Work Pending</Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box fontSize={"14px"} fontWeight={"500"}>
                      Admission Docs:
                    </Box>
                    <Box fontSize={"14px"} fontWeight={"500"}>
                      File Ready:
                    </Box>
                  </Box>
                  <Box>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Box
                        display={"flex"}
                        fontSize={"36px"}
                        fontWeight={"500"}
                        lineHeight={"52px"}
                      >
                        {dashboardStats.pendingFileAdmission}
                      </Box>
                      <Box
                        display={"flex"}
                        fontSize={"36px"}
                        fontWeight={"500"}
                        lineHeight={"52px"}
                      >
                        {dashboardStats.pendingFileReady}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </SimpleGrid>
          </Box>
          <Box
            colStart={4}
            border={"1px solid #e9e9e9"}
            padding={"20px"}
            borderRadius={"10px"}
          >
            <Box
              display={"flex"}
              flexDirection={{
                base: "column",
                md: "column",
                lg: "row",
                lg: "row",
              }}
            >
              <Box w={{ base: "100%", md: "100%", lg: "50%", lg: "50%" }}>
                <Heading alignItems={"center"} {...cardTitle}>
                  Monthly Statistics
                </Heading>
              </Box>
            </Box>
            <Box>
              <MonthWiseReport dataPoints={fileStats} />
            </Box>
          </Box>
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: "1", md: "1", lg: "1", lg: "1" }}
          gap={4}
        ></SimpleGrid>

        <SimpleGrid
          columns={{ base: "1", md: "1", lg: "2", lg: "2" }}
          gap={4}
          rowSpan={{ base: "1", md: "1", lg: "2", lg: "2" }}
        >
          <Box
            border={"1px solid #e9e9e9"}
            colStart={4}
            marginTop={"20px"}
            borderRadius={"10px"}
            w={"100%"}
            marginBottom={"20px"}
            padding={"20px"}
          >
            <Heading alignItems={"center"} w={"50%"} {...cardTitle}>
              Visa Statistics
            </Heading>
            <Channel dataPoints={visaStats} />
          </Box>
          <Box
            borderRadius={"10px"}
            marginTop={"20px"}
            padding={"20px"}
            marginBottom={"20px"}
            border={"1px solid #e9e9e9"}
          >
            <Heading
              fontSize={"20px"}
              fontWeight={"400"}
              color={colors.black}
              marginTop={"0px"}
            >
              Recent Activity
            </Heading>
            <Grid
              name={"logs"}
              hasAction={false}
              hasColumnDrop={false}
              columns={GRID_COLUMNS.DASHBOARD_LOGS}
              data={logStats}
            />
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default AdminDashboard;
