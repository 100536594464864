const CREATE = (enquiryData) => {
  const payload = {
    branchId: enquiryData.branchId,
    counsellorId: enquiryData.counsellorId,
    telecallerId: enquiryData.teleCallerId,
    enquiryStatusId: enquiryData.enquiryStatusId,
    followUpDate: enquiryData.followUpDate,
    name: enquiryData.name,
    phoneNumber: enquiryData.phoneNumber,
    guardianName: enquiryData.father,
    address: enquiryData.address,
    dob: enquiryData.dob,
    emailId: enquiryData.emailId,
    ieltsScore: enquiryData.ielts,
    listeningScore: enquiryData.listeningScore,
    readingScore: enquiryData.readingScore,
    writingScore: enquiryData.writingScore,
    speakingScore: enquiryData.speakingScore,
    qualStreamId: enquiryData.qualStreamId,
    passoutYear: enquiryData.passoutYear,
    boardId: enquiryData.boardId,
    lastPercentage: enquiryData.lastPercentage,
    visaCountryId: enquiryData.visaCountryId,
    visaTypeId: enquiryData.visaTypeId,
    referenceId: enquiryData.referenceId,
    remarks: enquiryData.remark,
    travelHistory: enquiryData.travelHistory,
    travelRemark: enquiryData.travelRemark,
    refusal: enquiryData.refusal,
    refusalRemark: enquiryData.refusalRemark,
  };

  return payload;
};
export const ENQUIRY_PAYLOAD = { CREATE };
