import Swal from "sweetalert";

export const SuccessAlert = (textMessage) => {
  return Swal({
    title: "Success",
    text: textMessage || "",
    icon: "success",
    button: {
      text: "OK",
      value: true,
      visible: true,
      className: "alertButton",
      closeModal: true,
    },
  });
};

export const InfoAlert = (textMessage) => {
  return Swal({
    title: "Info",
    text: textMessage,
    icon: "info",
    button: {
      text: "OK",
      value: true,
      visible: true,
      className: "alertButton",
      closeModal: true,
    },
  });
};

export const WarningAlert = (textMessage, titleMessage = "Error") => {
  return Swal({
    title: titleMessage,
    text: textMessage,
    icon: "warning",
    buttons: {
      confirm: {
        text: "OK",
        value: true,
        visible: true,
        className: "",
        closeModal: true,
      },
    },
    dangerMode: true,
  });
};

export const ConfirmationAlert = (
  title,
  text,
  yesClick,
  noClick,
  icon = false,
  buttons = { confirm: "Yes", cancel: "No" }
) => {
  return Swal({
    title: title,
    text: text,
    icon: icon ? icon : "warning",
    dangerMode: true,
    buttons: {
      confirm: buttons.confirm,
      cancel: buttons.cancel,
    },
  }).then((ifYes) => {
    if (ifYes) {
      yesClick();
    }
    if (!ifYes) {
      noClick();
    }
  });
};
