import {
  BRANCHES,
  BRANCH_ID,
  BRANCH_NAME,
  GET_BRANCH_ID,
  REBIND_BRANCHES,
} from "../../utils/constants";

const initialState = {
  userBranchId: "",
  isRebind: false,
};
export const branchReducer = (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case BRANCH_ID: {
      return {
        ...state,
        userBranchId: payload,
      };
    }
    case GET_BRANCH_ID: {
      return {
        ...state,
        userBranchId: state.userBranchId,
      };
    }
    case BRANCH_NAME: {
      return {
        ...state,
        name: payload,
      };
    }
    case BRANCHES: {
      return {
        ...state,
        branches: payload,
      };
    }
    case REBIND_BRANCHES: {
      return {
        ...state,
        isRebind: payload,
      };
    }

    default: {
      return state;
    }
  }
};
