import {
  Box,
  FormLabel,
  FormControl,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";

const TextArea = ({
  label = {
    text: "",
    fontSize: "",
    colors: "",
    variant: "",
  },
  value = "",
  formControl = { id: "" },
  box = { marginBottom: 5 },
  variant = "flushed",
  type = "text",
  size = "md",
  border = "none",
  backgroundColor,
  borderStyle = "solid",
  padding = 2,
  borderRadius = 5,
  name,
  placeholder,
  onChange,
  borderBottom,
  width,
  isRequired,
  isReadOnly,
  icon = {
    visible: false,
  },
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = () => {
    setIsVisible((_) => !_);
  };

  const labelProps = {
    ...(!!value && { transform: "scale(0.85) translateY(-24px)!important" }),
  };

  return (
    <Box marginBottom={box.marginBottom}>
      <InputGroup size="md">
        <FormControl
          variant="floating"
          id={formControl.id}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
        >
          <FormLabel
            fontSize={label.fontSize}
            color={label.colors}
            isRequired={isRequired}
            variant={label.variant}
            {...labelProps}
          >
            {label.text}
          </FormLabel>
          <Textarea
            variant={variant}
            name={name}
            placeholder={placeholder}
            size={size}
            resize={"none"}
            value={value}
            onChange={onChange}
          />
        </FormControl>
      </InputGroup>
    </Box>
  );
};

export default TextArea;
