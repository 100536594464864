import React, { useEffect } from "react";
import Navbar from "../components/header/navbar";
import { Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../components/header/topbar";
import ErrorBoundary from "../../pages/error-boundary";
import ProtectedRoutes from "../../routes/protected-routes";
import { useNavigate } from "react-router-dom";
import layoutHOC from "../../core/HOC/layoutHOC";

const Layout = ({
  isNav = true,
  isFooter = true,
  isAuthRequired = true,
  ...props
}) => {
  const dispatch = useDispatch();
  const selector = useSelector((_) => _);
  const navigate = useNavigate();
  const dependency = {
    ...props.permission,
    branchId: props.branchId,
  };

  const innerComponent = (navigate) => (
    <ErrorBoundary {...props} navigate={navigate}>
      {isNav && (
        <Box
          display={"flex"}
          width={"100%"}
          marginTop={"70px"}
          minH={"calc(100vh - 70px)"}
        >
          <TopBar />
          <Navbar />
          <Box className={selector.mainReducer.layout.wrapper}>
            {/* {props.children} */}
            <props.Component {...dependency} />
          </Box>
        </Box>
      )}
      {!isNav && (
        <Box marginTop={"0px!important"} minH={"calc(100vh - 0px)"}>
          {/* {props.children} */}
          <props.Component {...dependency} />
        </Box>
      )}
    </ErrorBoundary>
  );
  if (isAuthRequired) {
    return <ProtectedRoutes>{innerComponent(navigate)}</ProtectedRoutes>;
  }
  return <>{innerComponent(navigate)}</>;
};

export default layoutHOC(Layout);
