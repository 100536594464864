import { combineReducers } from "@reduxjs/toolkit";
import mainReducer from "./reducer";
import authReducer from "./sign-in";
import { branchReducer } from "./branch";
import pendingDocumentsReducer from "./pending-documents";

export default combineReducers({
  mainReducer,
  auth: authReducer,
  branch: branchReducer,
  pendingDocuments:pendingDocumentsReducer
});
