import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import * as yup from "yup";
const {
  ALPHA_NUMERIC_VALIDATOR,
  DATE_VALIDATOR,
  DECIMAL_VALIDATOR,
  FILE_VALIDATOR,
  GUID_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  STRING_VALIDATOR,
  POSITIVE_INTEGER
} = SCHEMA_VALIDATOR;

const IMMIGRATION_VALIDATION_SCHEMA = yup.object({
  dob: DATE_VALIDATOR,
  qualStreamId: GUID_VALIDATOR,
  ieltsScore: DECIMAL_VALIDATOR,
  listeningScore: DECIMAL_VALIDATOR,
  readingScore: DECIMAL_VALIDATOR,
  writingScore: DECIMAL_VALIDATOR,
  speakingScore: DECIMAL_VALIDATOR,
  address: ALPHA_NUMERIC_VALIDATOR,
  phoneNumber: PHONE_NUMBER_VALIDATOR,
  father: STRING_VALIDATOR,
  applicantName: STRING_VALIDATOR,
  boardId: GUID_VALIDATOR,
  visaCountryId: GUID_VALIDATOR,
  districtId: GUID_VALIDATOR,
  stateId: GUID_VALIDATOR,
  visaTypeId: GUID_VALIDATOR,
  alternateNo: PHONE_NUMBER_VALIDATOR,
  passportNo: ALPHA_NUMERIC_VALIDATOR,
  file: FILE_VALIDATOR,
  lastPercentage:POSITIVE_INTEGER,
  passoutYear:POSITIVE_INTEGER,
  tenthPercentage:POSITIVE_INTEGER,
  twelfthPercentage:POSITIVE_INTEGER,
  // qualStreamId:POSITIVE_INTEGER,
  streamPercentage:POSITIVE_INTEGER,
  best3Percentage:POSITIVE_INTEGER,
});

export default IMMIGRATION_VALIDATION_SCHEMA;
