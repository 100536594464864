import React, { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Collapse,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import Pagination from "../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES, PAGE_SIZE, ROLES } from "../../core/utils/constants";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import SelectInput from "../../theme/components/input/select-input";
import { IoMdAddCircleOutline } from "react-icons/io";
import { GoFilter } from "react-icons/go";
import useTableHeightFix from "../../core/hooks/useTableHeightFix";
import api from "../../core/api";
import {
  getFilterCount,
  createFilterPayload,
  deleteById,
  getAll,
  handleFilterChange,
  handleResetState,
  CREATE_RESPONSE,
} from "../../core/utils/common-function";
import DeleteModal from "../../theme/components/modals/delete-modal";
import { useSelector } from "react-redux";
import Grid from "../../theme/components/grid/grid";

const User = (props) => {
  const navigate = useNavigate();
  const selector = useSelector((_) => _);
  const hasDelete = selector.auth.login.RoleId == ROLES.ADMINISTRATOR;
  const hasExport =
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [show, setShow] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userId, setUserId] = useState(null);
  const [activeFilter, setActiveFilter] = useState(0);
  const [district, setDistrict] = useState([]);
  const [state, setState] = useState([]);
  const [filterState, setFilterState] = useState({});
  const [columns, setColumns] = useState(
    GRID_COLUMNS.USER.filter((x) => x.Active && x.Visibility)
  );
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
    branchId: selector.branch.userBranchId,
  });
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };

  const handleToggle = () => {
    setShow(!show);
  };

  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
  };
  const onModelClose = () => {
    setDeleteId(null);
    onClose();
  };
  const onDelete = async () => {
    await deleteById("User", userId);
    getAllRecords();
    setIsDeleteOpen(false);
    onModelClose();
  };
  const getAllPromises = () => {
    const state = getAll("State", payload);

    Promise.all([state])
      .then((res) => {
        const [stat] = res;
        const states = stat.data.data.map((sta) => ({
          text: sta.name,
          value: sta.stateId,
        }));
        setState(states);
      })
      .catch((err) => {});
  };

  const getAllRecords = () => {
    api
      .post("/User/getAll", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.USER, res.data.data.data));
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);

  useEffect(() => {
    document.title = "User | Digital Filing";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllRecords();
      getAllPromises();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId]);

  return (
    <Box>
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.USER)} />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>User</Heading>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>
            {props.hasCreate && (
              <Button
                variant={"primary"}
                onClick={() => {
                  navigate(ROUTE_LINKS[MENU_NAMES.ADD_USER], {
                    state: {
                      isEdit: false,
                    },
                  });
                }}
              >
                <IoMdAddCircleOutline fontSize={"20px"} />
                <Text marginLeft={"5px"} className="btnText">
                  Create New
                </Text>
              </Button>
            )}
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "5", lg: "5" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="text"
                  name="Name"
                  value={filterState?.Name?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  placeholder={""}
                  marginTop="20px"
                  formControl={{ id: "Name" }}
                  label={{ text: "Name" }}
                />
                <TextInput
                  type="text"
                  name="EmailId"
                  value={filterState.EmailId?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  placeholder={""}
                  marginTop="20px"
                  formControl={{ id: "Email" }}
                  label={{ text: "Email" }}
                />
                <TextInput
                  type="text"
                  name="PhoneNumber"
                  value={filterState.PhoneNumber?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  placeholder={""}
                  marginTop="20px"
                  formControl={{ id: "Phone" }}
                  label={{ text: "Phone" }}
                />
                <TextInput
                  type="date"
                  name="Dob"
                  value={filterState.Dob?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  placeholder={""}
                  marginTop="20px"
                  formControl={{ id: "DOB" }}
                  label={{ text: "DOB" }}
                />
                <SelectInput
                  formControl={{ id: "isActive", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.IsActive?.value}
                  name="IsActive"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "Status",
                  }}
                  options={[
                    { text: "Active", value: true },
                    { text: "Inactive", value: false },
                  ]}
                />
                <SelectInput
                  formControl={{ id: "stateId", variant: "floating" }}
                  isReadOnly={false}
                  value={filterState?.StateId?.value}
                  name="StateId"
                  onChange={(e) => {
                    handleFilterChange(e, filterState, setFilterState);
                    api
                      .get(`District/getByState/${e.target.value}`)
                      .then((res) => {
                        const Districts = res.data.data.map((dist) => ({
                          text: dist.name,
                          value: dist.districtId,
                        }));
                        setDistrict(Districts);
                      })
                      .catch((err) => {
                        console.log(err, "err");
                      });
                  }}
                  label={{
                    text: "State",
                  }}
                  options={state}
                />
                <SelectInput
                  formControl={{ id: "districtId", variant: "floating" }}
                  isReadOnly={false}
                  name="DistrictId"
                  value={filterState?.DistrictId?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "District",
                  }}
                  options={district}
                  isDisabled={!filterState?.StateId?.value}
                />
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>
        <Grid
          name={"user"}
          columns={GRID_COLUMNS.USER}
          aColumns={columns}
          data={data}
          permission={props}
          hasDelete={hasDelete}
          hasExport={hasExport}
          setColumns={(e) => setColumns(e)}
          viewRoute={ROUTE_LINKS[MENU_NAMES.VIEW_USER]}
          editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_USER]}
          setRecordId={(id, props) => {
            setUserId(id);
            if (props.isDelete) setIsDeleteOpen(true);
          }}
            
        />
        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) =>
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          handleRowStateChange={(event) => {
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: event.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
      {/* <DeleteModal
        isOpen={isOpen}
        onClose={() => {
          // setIsDeleteOpen(false);
          onClose();
        }}
        onDelete={() => {
          deleteById("User", deleteId);
          setDeleteId(null);
          getAllRecords();
          onClose();
        }}
      /> */}
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
        onDelete={onDelete}
      />
    </Box>
  );
};
export default User;
