import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { colors } from "../../../theme/styles/colors";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MonthWiseReport = ({ dataPoints = [] }) => {
  const createData = () => {
    let result = [];
    for (let index = 0; index < 12; index++) {
      result.push({
        x: index + 1,
        y: 0,
      });
    }
    return result;
  };
  const defaultDataPoints = createData();
  const options = {
    animationEnabled: true,
    theme: "light2",
    axisX: {
      title: "Month",
    },
    axisY: {
      title: "Value",
      gridThickness: 0.2,
      stripLines: [
        {
          value: 0,
          showOnTop: true,
          color: "#F1F5F9",
          thickness: 1,
        },
      ],
    },
    data: [
      {
        color: colors.purple.purple600,
        showInLegend: true,
        legendText: "Visa Granted",
        markerType: "circle",
        dataPoints: dataPoints[0] || defaultDataPoints,
      },
      {
        color: colors.cyan.cyan600,
        showInLegend: true,
        legendText: "Visa Refused",
        markerType: "circle",
        dataPoints: dataPoints[1] || defaultDataPoints,
      },
      {
        color: colors.pink.pink600,
        showInLegend: true,
        legendText: "File On-Hold",
        markerType: "circle",
        dataPoints: dataPoints[2] || defaultDataPoints,
      },
    ],
  };
  return (
    <CanvasJSChart
      options={options}
      containerProps={{ width: "100%", height: "300px" }}
      marginTop={"0px"}
    />
  );
};

export default MonthWiseReport;
