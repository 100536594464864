import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppRoutes from "./routes";
import { Box } from "@chakra-ui/react";
import { USER_AUTH_TOKEN_KEY } from "./core/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setBranches,
  setBranchId,
  setBranchName,
  setPendingDocuments,
  userSignIn,
} from "./core/store/actions";
import { getAll, PARSE_JWT } from "./core/utils/common-function";
import { useEffect, useState } from "react";
function App() {
  const dispatch = useDispatch();
  const selector = useSelector((_) => _);
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const getAllPromises = (parsedToken) => {
    const Branch = getAll("Branch", payload);
    const FileDocument = getAll("FileDocument", payload);

    Promise.all([Branch, FileDocument])
      .then((res) => {
        const [branch, fileDocument] = res;
        const Branch = branch.data.data.map((data) => ({
          text: data.name,
          value: data.branchId,
        }));
        const branchId =
          parsedToken != undefined && parsedToken.BranchId != ""
            ? parsedToken.BranchId
            : branch.data.data[0].branchId;
        const currentBranch = Branch.find((x) => x.value === branchId);

        dispatch(setPendingDocuments(fileDocument.data.data));
        dispatch(setBranches(Branch));
        dispatch(setBranchName(currentBranch.text));
        dispatch(setBranchId(branchId));
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const recoverReduxState = async () => {
    const token = JSON.parse(localStorage.getItem(USER_AUTH_TOKEN_KEY));
    if (token) {
      const parsedToken = PARSE_JWT(token, false);
      dispatch(userSignIn(parsedToken));
    }
  };

  useEffect(() => {
    recoverReduxState();
  }, []);

  return (
    <Box marginTop={"0px"} width={"100%"}>
      <BrowserRouter>
        <Routes>
          {AppRoutes.map((item, index) => (
            <Route key={index} {...item} />
          ))}
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
