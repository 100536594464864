import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { colors } from "../../../theme/styles/colors";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Channel = ({ dataPoints = [] }) => {
  const defaultDataPoints = [
    {
      name: `No stats found`,
      indexLabel: "",
      x: 100,
      y: 100,
      color: colors.gray.gray400,
    },
  ];
  const options = {
    animationEnabled: true,
    responsive: true,
    maintainAspectRatio: false,
    theme: "light2",
    toolTip: {
      enabled: dataPoints.length ? true : false,
    },
    subtitles: [
      {
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
        color: "red",
      },
    ],
    data: [
      {
        type: "doughnut",
        showInLegend: true,
        dataPoints: dataPoints.length ? dataPoints : defaultDataPoints,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart
        options={{
          ...options,
        }}
        containerProps={{ width: "100%", height: "350px" }}
      />
    </div>
  );
};
export default Channel;
