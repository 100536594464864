import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import React, { Component } from "react";
import { colors } from "../theme/styles/colors";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display={"flex"}
          gap={"40px"}
          flexDirection={"column"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <Box display={"flex"} justifyContent={"center"}>
            <Heading
              fontSize={{ base: "75px", md: "120px", lg: "156px" }}
              color={colors.primaryColor}
            >
              500
            </Heading>
          </Box>
          <Box display={"flex"} gap={"20px!important"} flexDirection={"column"}>
            <Box display={"flex"} gap={"0px"} flexDirection={"column"}>
              <Heading
                alignItems={"center"}
                display={"flex"}
                justifyContent={"center"}
                fontSize={{ base: "36px", md: "42px", lg: "48px" }}
              >
                OOPS! Something went wrong
              </Heading>
            </Box>
            <Text
              alignItems={"center"}
              display={"flex"}
              maxW={"500"}
              textAlign={"center"}
              margin={"auto"}
              justifyContent={"center"}
              fontSize={{ base: "13px", md: "15px", lg: "22px" }}
            >
              Please either refresh the page or return home to try again.
            </Text>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              variant="primary"
              onClick={() => {
                this.props.navigate(-1, { replace: true });
              }}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
