import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "../theme/styles/colors";

const NoPageFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box display={"flex"} gap={"40px"} flexDirection={"column"} justifyContent={"center"} height={"100vh"}>
      <Box display={"flex"} justifyContent={"center"}>
        <Heading fontSize={{ base: "75px", md: "120px", lg: "156px" }} color={colors.primaryColor}> 404</Heading>
      </Box>
      <Box display={"flex"} gap={"20px!important"} flexDirection={"column"}>
        <Box display={"flex"} gap={"0px"} flexDirection={"column"}>
          <Heading
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            fontSize={{ base: "36px", md: "42px", lg: "48px" }}
          >
           OOPS! That's an error
          </Heading>
          <Heading
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            fontSize={{ base: "36px", md: "42px", lg: "48px" }}
          >
            We couldn't find this page
          </Heading>
        </Box>
        <Text alignItems={"center"}
          display={"flex"}
          maxW={"500"}
          textAlign={"center"}
          margin={"auto"}
          justifyContent={"center"}
          fontSize={{ base: "13px", md: "15px", lg: "22px" }}>The page are you looking for might have been removed or temporarily Unavailable!</Text>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Button onClick={goBack} variant="primary">
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

export default NoPageFound;
