import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MENU_NAMES } from "../core/utils/constants";
import { ROUTE_LINKS } from "../core/utils/route-links";
import { colors } from "../theme/styles/colors";

const UnAuthorized = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    navigate(ROUTE_LINKS[MENU_NAMES.UN_AUTHORIZED]);
  }, []);

  return (
    <Box display={"flex"} gap={"40px"} flexDirection={"column"} justifyContent={"center"} height={"100vh"}>
      <Box display={"flex"} justifyContent={"center"}>
      <Heading fontSize={{ base: "75px", md: "120px", lg: "156px" }} color={colors.primaryColor}> 401</Heading>
      </Box>
      <Box display={"flex"} gap={"20px!important"} flexDirection={"column"}>
        <Box display={"flex"} gap={"0px"} flexDirection={"column"}>
          <Heading
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            fontSize={{ base: "36px", md: "42px", lg: "48px" }}
          >
          Unauthorized Access:
          </Heading>
          <Heading
            alignItems={"center"}
            display={"flex"}
            justifyContent={"center"}
            fontSize={{ base: "36px", md: "42px", lg: "48px" }}
          >
           Access is denied due to invalid credentials
          </Heading>
        </Box>
        <Text alignItems={"center"}
          display={"flex"}
          maxW={"500"}
          textAlign={"center"}
          margin={"auto"}
          justifyContent={"center"}
          fontSize={{ base: "13px", md: "15px", lg: "22px" }}>You do not have permission to view this directory or page using the credentials that you supplied.</Text>
      </Box>


      <Box display={"flex"} justifyContent={"center"}>
        <Button variant={"primary"} onClick={goBack}>
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

export default UnAuthorized;
