import {
  Box,
  Button,
  FormLabel,
  FormControl,
  Image,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { convertDateFormat } from "../../../core/utils/common-function";

const TextInput = ({
  label = {
    text: "",
    fontSize: "",
    colors: "",
    variant: "",
    fontWeight: "600",
  },
  defaultValue="",
  formControl = { id: "" },
  box = { marginBottom: 5 },
  variant = "flushed",
  type = "text",
  size = "md",
  name,
  value,
  placeholder = "",
  onChange,
  isRequired,
  isReadOnly,
  isDisabled,
  accept,
  icon = {
    visible: false,
    isReadOnly: false,
  },
  minVal = "",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [cDate, setCDate] = useState(defaultValue);

  const handleClick = () => {
    setIsVisible((_) => !_);
  };
  if (type == "number") {
    minVal = 0;
  }

  useEffect(() => {
    if (type == "date") {
      const date = new Date();
      const uDate = date.toISOString().substring(0, 10);
      setCDate(convertDateFormat(date, "yyyy-mm-dd"));
    }
  }, []);

  return (
    <Box marginBottom={box.marginBottom}>
      <InputGroup size="md">
        <FormControl
          variant="floating"
          id={formControl.id}
          isRequired={isRequired}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
        >
          <Input
            variant={variant}
            name={name}
            min={minVal}
            placeholder={placeholder}
            type={type == "password" ? (isVisible ? "text" : "password") : type}
            size={size}
            value={value}
            onChange={onChange}
            accept={accept}
            defaultValue={cDate}
            isDisabled={isReadOnly}
          />
          <FormLabel
            fontSize={label.fontSize}
            color={label.colors}
            isRequired={isRequired}
            variant={label.variant}
          >
            {label.text}
          </FormLabel>
        </FormControl>
        {icon.visible && (
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick}>
              {isVisible ? (
                <Image width={"30px"} src="/images/eye-open.svg" />
              ) : (
                <Image width={"30px"} src="/images/eye-close.svg" />
              )}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};

export default TextInput;
