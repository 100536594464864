import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { colors } from "../../../theme/styles/colors";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const LineChart = ({ dataPoints = [] }) => {
  const createData = () => {
    let result = [];
    for (let index = 0; index < 12; index++) {
      result.push({
        x: new Date(new Date().getFullYear(), index),
        y: 0,
      });
    }
    return result;
  };
  const defaultDataPoints = createData();
  const options = {
    animationEnabled: true,
    responsive: true,
    maintainAspectRatio: false,
    theme: "light2",
    axisX: {
      title: "Month",
      valueFormatString: "MMM",
      interval: 1,
      intervalType: "month"
    },
    axisY: {
      title: "Total Number",
      gridThickness: 0.2,
      lineThickness: 1,
      stripLines: [
        {
          value: 0,
          showOnTop: true,
          color: "#F1F5F9",
          thickness: 1,
        },
      ],
    },
    data: [
      {
        xValueFormatString: "Enquiry",
        lineColor: colors.purple.purple600,
        color: colors.purple.purple600,
        type: "spline",
        showInLegend: true,
        legendText: "Enquiry",
        markerType: "circle",
        dataPoints: dataPoints[0] || defaultDataPoints,
      },
      {
        xValueFormatString: "Enrollment",
        lineColor: colors.cyan.cyan600,
        color: colors.cyan.cyan600,
        type: "spline",
        showInLegend: true,
        legendText: "Enrollment",
        markerType: "circle",
        dataPoints: dataPoints[1] || defaultDataPoints,
      },
      {
        xValueFormatString: "File Ready",
        lineColor: colors.pink.pink600,
        color: colors.pink.pink600,
        type: "spline",
        showInLegend: true,
        legendText: "File Ready",
        markerType: "circle",
        dataPoints: dataPoints[2] || defaultDataPoints,
      },
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default LineChart;
