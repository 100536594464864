import { extendTheme } from "@chakra-ui/react";
import { colors } from "./styles/colors";
import { tabsTheme } from "./styles/tabs";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-25px)!important",
};
const theme = extendTheme({
  colors: {
    primary: {
      main: "#1AAA00",
      color: "#fff",
      hover: { main: "#0f8b03", color: "#fff" },
    },
    secondary: {
      main: "#2299b0",
      hover: { main: "#2276b0", color: "#fff" },
      color: "#fff",
    },
    warning: {
      main: "#696969",
      hover: { main: "#dadfe2", color: "#000" },
      color: "#fff",
    },
  },
  components: {
    Button: {
      baseStyle: {},
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
        lg: {
          h: "42px",
          fontSize: "lg",
        },
        sm: {
          h: "13px",
          fontSize: "lg",
        },
      },
      variants: {
        "with-shadow": {
          bg: "#e61515",
          color: "white",
          fontSize: "14px",
          boxShadow: "0 0 2px 2px #efdfde",
        },
        warning: {
          bg: colors.gray.gray300,
          color: colors.textColour,
          fontSize: "14px",
          fontWeight: "500",
          _hover: { bg: colors.gray.gray300, color: colors.textColour },
        },

        danger: {
          fontSize: "12px",
          color: colors.white,
          backgroundColor: colors.primaryColor,
          height: "36px",
        },

        brandPrimary: {
          fontSize: "12px",
          backgroundColor: colors.gray.gray300,
          color: colors.textColour,
          height: "36px",
          _hover: { bg: colors.gray.gray300, color: colors.textColour },
        },

        primary: {
          color: colors.white,
          bg: colors.primaryColor,
          fontSize: "14px",
          fontWeight: "500",
          _hover: { bg: "#f0ac00bf", color: colors.white },
        },

        secondary: {
          bg: "#f0ac0040",
          color: colors.primaryColor,
          fontWeight: "500",
          fontSize: "14px",
          _hover: { bg: colors.primaryColor, color: colors.white },
        },

        primaryOutline: {
          bg: "transparent",
          color: colors.primaryColor,
          border: "1px solid #F0AC00",
          fontWeight: "500",
          fontSize: "14px",
        },

        secondaryOutline: {
          bg: colors.gray.gray300,
          color: colors.textColour,
          fontSize: "14px",
          fontWeight: "500",
          _hover: { bg: colors.gray.gray300, color: colors.textColour },
        },
      },
      defaultProps: {
        size: "lg",
        variant: "sm",
        colorScheme: "green",
      },
    },

    // Input theme
    Input: {
      variants: {
        flushed: {
          field: {
            border:"0px",
            fontSize: "12px",
            color: colors.black,
            borderRadius: "2px",
            p: "10px 10px 5px 5px",
            height: "40px",
            size: "lg",
            borderBottom: "1px solid #00000042!important",
          },
        },

        errored: {
          field: {
            fontSize: "12px",
            borderRadius: "2px",
            p: "10px 10px 5px 5px",
            height: "40px",
            size: "lg",
            borderBottom: `1px solid red`,
          },
        },
      },
    },
    // MutlipleSelect
    Menu: {
      variants: {
        roundLeft: {
          button: {
            border:"none",
            borderRadius: "0px",
            borderBottom: "1px solid #00000042!important",
            textAlign: "left",
            fontSize: "12px",
            border:"0px",
          },
         
          MenuList: {
            height: "200px",
            py: "4",
            borderRadius: "xl",
          },
          item: {
            border: "black",
            color: "black",
          },
          groupTitle: {
            backgroundColor: "red !important",
          },
          command: {},

          divider: {},
        },
      },
    },
    // Select inpput
    Select: {
      variants: {
        flushed: {
          field: {
            border:"0px",
            borderRadius: "0px",
            borderBottom: "1px solid #00000042!important",
            p: "10px 10px 5px 5px",
            height: "40px",
            fontSize: "12px",
            color: colors.black,
          },
          icon: {
            right: "-8px",
            _dark: {
              color: "#000;",
            },
          },
        },
        errored: {
          field: {
            fontSize: "12px",
            borderRadius: "2px",
            p: "10px 10px 5px 5px",
            borderBottom: `1px solid red`,
          },
          icon: {
            right: "-8px",
            _dark: {
              color: "#000;",
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        flushed: {
          borderRadius: "2px",
          borderBottom: "1px solid #00000042!important",
          p: "10px 10px 5px 5px",
          fontSize: "12px",
          color: colors.black,
        },
        errored: {
          fontSize: "12px",
          borderRadius: "2px",
          p: "10px 10px 5px 5px",
          borderBottom: `1px solid red`,
        },
      },
    },
    // Heading
    Heading: {
      textStyle: {
        h2: {
          fontSize: ["24px", "30px"],
        },
      },
      variants: {
        h1: {
          color: colors.black,
          fontSize: "30px",
          fontWeight: "700",
        },
        h2: {
          color: colors.black,
          fontSize: "30px",
          textAlign: "center",
        },
        h3: {
          fontSize: "18px",
          color: colors.black,
          marginBottom: "20px",
          marginTop: "20px",
        },
        h4: {
          fontSize: "18px",
          marginBottom: "20px",
          marginTop: "20px",
          fontWeight: "500",
        },
        h6: {
          fontSize: "13px",
          color: "#000000",
        },
        h5: {
          fontSize: "14px",
          textAlign: "center",
          fontWeight: "400",
        },
      },
      sizes: {
        base: {
          fontSize: "14px",
        },
        sm: {
          fontSize: "14px",
        },
        md: {
          fontSize: "48px",
        },
        lg: {
          fontSize: "48px",
        },
      },
    },
    // label
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, select:not(:placeholder-shown) ~ label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            "input:not(:disabled)": {},
            label: {
              top: "-10px",
              left: "-8px",
              zIndex: 2,
              position: "absolute",
              backgroundColor: "transparent",
              border: "none",
              pointerEvents: "none",
              fontSize: "12px",
              mx: 1,
              px: 1,
              my: 6,
              transformOrigin: "left top",
              fontWeight: "400",
              color: colors.gray.gray700,
            },
          },
        },
      },
    },
    Modal: {
      baseStyle: () => ({
        dialog: {
          maxWidth: ["33%", "33%", "33%"],
          minWidth: "20%",
          bg: "white",
        },
      }),
    },

    Tabs: tabsTheme,
    variants: {
      colorfulVariant: {
        tab: {
          color: colors.primaryColor,
        },
        tablist: {
          borderBottom: "2x solid #F0AC00",
        },
        tabpanel: {
          border: "2px solid",
          borderBottomRadius: "lg",
          borderTopRightRadius: "lg",
        },
      },
    },
  },
});

export default theme;
