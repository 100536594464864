import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme';
import { Provider } from 'react-redux';
import Store from './core/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ChakraProvider theme={theme} >
      <Provider store={Store}>
        <App />
      </Provider>
    </ChakraProvider>
  </>
);