import {
  FormControl,
  Box,
  Button,
  WrapItem,
  CircularProgress,
} from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { Image, Heading, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TextInput from "../../theme/components/input/text-input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { colors } from "../../theme/styles/colors";
import api from "../../core/api";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import { SuccessAlert, WarningAlert } from "../../core/utils/common-sweet-alert";

function ResetPassword() {
  const navigate = useNavigate();
  const params = useLocation()
  const [isLoading, setIsLoading] = useState(false);
  const [initialState, setInitialState] = useState({
    password: "",
    confirmPassword: ""
  });

  const validationSchema = yup.object({
    password: SCHEMA_VALIDATOR.PASSWORD_VALIDATOR,
    confirmPassword: SCHEMA_VALIDATOR.CONFIRM_PASSWORD_VALIDATOR
  });
  useEffect(() => {
    document.title = "Reset Password | Digital Filing";
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialState}
      onSubmit={(values, actions) => {
        setIsLoading(true);
        api
          .post(`/Account/reset-password`, {
            token: params.search.split("token=")[1],
            password: values.password,
          })
          .then((res) => {
            setIsLoading(false);
            if(res.data.statusCode === 200) {
              navigate("/")
              SuccessAlert(res.data.message)
            }else if(res.data.statusCode === 400){
              WarningAlert(res.data.message)
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <Form
          noValidate={true}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Flex dir="row">
            <Box
              display={{ base: "none", sm: "none", md: "block", lg: "block" }}
              border={1}
              borderColor={"green"}
              borderWidth={1}
              h={"100vh"}
              w={"50%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box className="login">
                <Box className="left-content"></Box>
              </Box>
            </Box>
            <Box
              border={1}
              padding={{ base: "30px", sm: "30px", md: "30px", lg: "30px" }}
              borderWidth={1}
              h={"100vh"}
              borderColor={"#F0AC00"}
              w={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box w={{ base: "100%", sm: "100%", md: "50%", lg: "50%" }}>
                <Box
                  position={"absolute"}
                  right={"0px"}
                  top={"10px"}
                  fontSize={"14px"}
                ></Box>
                {/* <Flex
                  justifyContent={"center"}
                  w={"100px"}
                  margin={"0px auto 20px"}
                >
                  <Image src="./images/HSLogo.png" />
                </Flex> */}

                <Stack spacing={6} marginBottom={"30px"}>
                  <Heading variant={"h2"}>Reset Password</Heading>
                  <Heading variant={"h5"}>
                    Enter your Password to proceed
                  </Heading>
                </Stack>
                <FormControl gap="3">


                  <TextInput
                    type="password"
                    isRequired={true}
                    name="password"
                    value={values.password}
                    label={{
                      text: "Password",
                      colors: errors.password ? "red !important" : "",
                      variant: errors.password ? "errored" : "",
                    }}
                    onChange={(e) => handleChange(e)}
                    placeholder={""}
                    paddingLeft={"0px"}
                    variant={errors.password ? "errored" : "flushed"}
                    isReadOnly={isLoading}
                    icon={{
                      visible: true,
                    }}
                  />

                  <TextInput
                    type="password"
                    isRequired={true}
                    name="confirmPassword"
                    value={values.confirmPassword}
                    label={{
                      text: "Confirm Password",
                      colors: errors.confirmPassword ? "red !important" : "",
                      variant: errors.confirmPassword ? "errored" : "",
                    }}
                    onChange={(e) => handleChange(e)}
                    placeholder={""}
                    paddingLeft={"0px"}
                    variant={errors.confirmPassword ? "errored" : "flushed"}
                    isReadOnly={isLoading}
                    icon={{
                      visible: true,
                    }}
                  />


                </FormControl>
                <Box>
                  <WrapItem gap={"10px"}>
                    <Button
                      variant={"warning"}
                      width={"100%"}
                      marginTop={"20px"}
                      type="button"
                      onClick={(e) => navigate("/")}
                    >
                      Back

                    </Button>
                    <Button
                      variant={"primary"}
                      width={"100%"}
                      marginTop={"20px"}
                      type="submit"
                    >
                      {isLoading ? (
                        <CircularProgress
                          isIndeterminate
                          size="24px"
                          color={colors.white}
                        />
                      ) : (
                        "Reset Password"
                      )}
                    </Button>
                  </WrapItem>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default ResetPassword;
